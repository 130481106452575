import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { exportToExcel } from "../../../utils/tableExports";

const TablePagination = ({
  startPage,
  currentPage,
  totalPages,
  itemsPerPage,
  handleItemsPerPageChange,
  toFirstPage,
  toPreviousPage,
  toNextPage,
  toLastPage,
  exportButton = false,
  setCurrentPage,
  exportFunc,
  setApiCallStatus,
  gotoPage,
}) => {
  const { t } = useTranslation();
  const isLinkEnabled = currentPage !== startPage;
  const isLinkDisabled = currentPage !== totalPages;
  const getPreviousPages = () => {
    const pages = [];
    for (let i = 2; i > 0; i--) {
      const pageNumber = currentPage - i;
      if (pageNumber > startPage) {
        pages.push(pageNumber);
      }
    }
    console.log(pages);
    return pages;
  };
  const getNextPages = () => {
    const pages = [];
    for (let i = 1; i <= 2; i++) {
      const pageNumber = currentPage + i;
      if (pageNumber < totalPages) {
        pages.push(pageNumber);
      }
    }
    return pages;
  };

  return (
    <div className="pagination_section_btm pb-md-0 pb-5">
      <div className="row justify-content-between">
        <div className="col-xl-auto col-sm-3 text-start table-export-button justify-content-sm-start justify-content-center">
          {exportButton && (
            <button onClick={exportFunc}>EXPORT TO EXCEL</button>
          )}
        </div>
        <div className="col-xl-auto col-sm-9 col-12 text-end order-xl-1">
          <div className="mt-2 mt-sm-0">
            <div className="w-sm_100">
              <nav>
                <ul className="pagination d-flex flex-wrap pagination-rounded-flat pagination-success m-0">
                  <li className="page-item page_prev">
                    <Link
                      className={`page-link ${isLinkEnabled ? "" : "disabled"}`}
                      onClick={toPreviousPage}
                      data-abc="true"
                    >
                      <i className="fa fa-angle-left"></i>
                    </Link>
                  </li>
                  {currentPage !== startPage && (
                    <li className="page-item">
                      <Link
                        className="page-link  page-link-item"
                        onClick={toFirstPage}
                        data-abc="true"
                      >
                        {startPage}
                      </Link>
                    </li>
                  )}

                  {currentPage - startPage > 3 && (
                    <li className="page-item">
                      <Link
                        className="page-link page-link-item"
                        data-abc="true"
                      >
                        ...
                      </Link>
                    </li>
                  )}
                  {/* Previous two pages */}
                  {getPreviousPages().map((pageNum) => (
                    <li key={pageNum} className="page-item">
                      <Link
                        className="page-link page-link-item"
                        onClick={() => gotoPage(pageNum)}
                        data-abc="true"
                      >
                        {pageNum}
                      </Link>
                    </li>
                  ))}

                  <li className="page-item page_active">
                    <Link className="page-link page-link-item" data-abc="true">
                      {currentPage ?? 0}
                    </Link>
                  </li>

                  {/* Next two pages */}
                  {getNextPages().map((pageNum) => (
                    <li key={pageNum} className="page-item">
                      <Link
                        className="page-link page-link-item"
                        onClick={() => gotoPage(pageNum)}
                        data-abc="true"
                      >
                        {pageNum}
                      </Link>
                    </li>
                  ))}

                  {totalPages - (currentPage+2) > 1 && (
                    <li className="page-item">
                      <Link
                        className="page-link page-link-item"
                        data-abc="true"
                      >
                        ...
                      </Link>
                    </li>
                  )}

                  {currentPage !== totalPages && (
                    <li className="page-item page_desable">
                      <Link
                        className="page-link page-link-item"
                        onClick={toLastPage}
                        data-abc="true"
                      >
                        {totalPages}
                      </Link>
                    </li>
                  )}
                  <li className="page-item page_next">
                    <Link
                      className={`page-link ${
                        isLinkDisabled ? "" : "disabled"
                      }`}
                      onClick={toNextPage}
                      data-abc="true"
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="col-xl-auto col-12">
          <div className="mt-2 mt-md-0 text-center">
            <span className="table_foot_text ">{`PAGE ${currentPage} OF ${totalPages} (${itemsPerPage} ITEMS)`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
