import React, { useState } from "react";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import CommonInput from "../../components/Common/CommonInputComponent";

const EventPayment = ({
    setActiveStep,
    registerFields,
    totalAmount,
    conversionFactor,
    handleSubmit,
    userData,
    transPass,
    setTransPass,
    balance,
    paymentMethod,
    eventPurchaseMutation
}) => {
    const { t } = useTranslation();
    const backgroundColor = "#e4f0f2";
    const [transPassCheck, setTransPassCheck] = useState(false);
    const [transPassResposne, setTransPassResposne] = useState({
        success: null,
        error: null,
    });
    const [submitButtonActive, setSubmitButtonActive] = useState(true);
    const transPassCheckData = ApiHook.CallTransPasswordCheck(
        transPass,
        transPassCheck,
        setTransPassCheck,
        setSubmitButtonActive,
        totalAmount,
        transPassResposne,
        setTransPassResposne,
        "e-wallet"
    );
    return (
        <>
            <div className="register-verification-container">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className="register-verification-container">
                        <CommonInput
                            type="text"
                            label="Total Amount To Pay"
                            backgroundColor={backgroundColor}
                            value={`${
                                conversionFactor?.symbolLeft
                            } ${CustomCurrencyConverter(
                                totalAmount,
                                conversionFactor?.value
                            )}`}
                            defaultValue=""
                            disabled={true}
                        />
                        {paymentMethod == 2 && (
                            <>
                                <CommonInput
                                    type="text"
                                    label="IBE #"
                                    backgroundColor={backgroundColor}
                                    value={userData?.username}
                                    defaultValue=""
                                    disabled={true}
                                />
                                <CommonInput
                                    type="password"
                                    label="Transaction Password"
                                    placeholder={"Your Transaction password"}
                                    backgroundColor={backgroundColor}
                                    value={transPass}
                                    onChange={(e) => {
                                        setTransPass(e.target.value);
                                    }}
                                    defaultValue=""
                                />
                                {transPassResposne?.error && (
                                    <span className="error-message-validator">
                                        {t(transPassResposne?.error)}
                                    </span>
                                )}

                                <button
                                    className="common-button"
                                    onClick={(e) => {
                                        setTransPassCheck(true);
                                        // handleFormValueChange(2, "paymentType", {
                                        //     shouldValidate: true,
                                        //     shouldDirty: true,
                                        // });
                                    }}
                                >
                                    CHECK
                                </button>
                            </>
                        )}
                        {transPassResposne?.success && (
                            <button
                                className="common-button"
                                disabled={submitButtonActive &&
                                    (eventPurchaseMutation.isLoading || eventPurchaseMutation.status === "success")
                                  }
                                onClick={(e) => {
                                    handleSubmit();
                                }}
                            >
                                SUBMIT
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventPayment;
