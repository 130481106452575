import React, { useEffect, useState } from "react";
import ProductLists from "../../components/shopping/ProductLists";
import { ApiHook } from "../../hooks/apiHook";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonInput from "../../components/Common/CommonInputComponent";
import { event } from "jquery";
import { tr } from "date-fns/locale";

const ShoppingLayout = () => {
    const param = useParams();
    const { t } = useTranslation();
    const items = ApiHook.CallRepurchaseItems();
    const [repurchaseType, setRepurchaseType] = useState(param?.type ?? "");
    const [courierType, setCourierType] = useState(param?.courier ?? "");
    const [activeStep, setActiveStep] = useState(1);
    const backGround = "#e4f0f2";
    const [errorsMessage, setErrorMessage] = useState({
        repurchaseError: "",
        courierError: "",
    });

    useEffect(() => {
        if (items?.data?.isBagladeshUser) {
            setCourierType("Courier");
        }
    }, [items?.data?.isBagladeshUser]);

    return (
        <>
            <div
                className="container-bg-design-container-flex"
                style={{ height: "100vh", backgroundColor: "#fff" }}
            >
                <div className="container-bg-design repurchase-cart-in-container">
                    <div style={{ zIndex: "1", position: "relative" }}>
                        <div className="common-div-header gta-b space-bottom-repurchase">
                            IBE REPURCHASE SHOPPING CART
                        </div>
                        <>
                            <div className="repurchase-input-container-flex container-fluid mb-md-0 mb-5">
                                <div>
                                    <div>
                                        <div className="repurchase-input-container rep-slect-input px-3">
                                            <CommonInput
                                                type="select"
                                                label="Select Repurchase Type"
                                                // backgroundColor={"#fff"}
                                                value={repurchaseType}
                                                onChange={(e) => {
                                                    setRepurchaseType(
                                                        e.target.value
                                                    );
                                                }}
                                                options={[
                                                    // "Select Delivery Mode",
                                                    "PCV",
                                                    "QSV",
                                                ]}
                                                defaultValue=""
                                                isLabelDisabled={true}
                                            />
                                            {errorsMessage?.repurchaseError !==
                                                "" && (
                                                <span className="error-message-validator">
                                                    {
                                                        errorsMessage?.repurchaseError
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {!items?.data?.isBagladeshUser && (
                                        <div className="col-md-6">
                                            <div className="repurchase-input-container px-3">
                                                <CommonInput
                                                    type="select"
                                                    label="Select Delivery Mode"
                                                    // backgroundColor={"#fff"}
                                                    value={courierType}
                                                    onChange={(e) => {
                                                        setCourierType(
                                                            e.target.value
                                                        );
                                                    }}
                                                    options={[
                                                        // "Select Delivery Mode",
                                                        "Courier",
                                                        "Selfpickup",
                                                    ]}
                                                    defaultValue=""
                                                    isLabelDisabled={true}
                                                />
                                                {errorsMessage?.courierError !==
                                                    "" && (
                                                    <span className="error-message-validator">
                                                        {
                                                            errorsMessage?.courierError
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="repurchase-cart-in-list-flex pb-lg-0 pb-5">
                                <div className="repurchase-cart-in-list d-flex justify-content-center">
                                    {items?.data && (
                                        <ProductLists
                                            products={items?.data?.productList}
                                            repurchaseType={repurchaseType}
                                            courierType={courierType}
                                            conversionFactor={
                                                items?.data?.conversionFactor
                                            }
                                            setErrorMessage={setErrorMessage}
                                            // isBagladeshUser={
                                            //     items?.data?.isBagladeshUser
                                            // }
                                            // setCourierType={setCourierType}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShoppingLayout;
