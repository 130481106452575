import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { GetTrainingCreditsReport } from "../../services/additonalModules/additionalFeature";
import moment from "moment";
import { exportToExcelNew } from "../../utils/tableExports";

const TrainScoreReport = () => {
    const { t } = useTranslation();

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [apiCallStatus1, setApiCallStatus1] = useState(false);
    const [filters, setFilters] = useState({});

    const START_PAGE = 1;

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(10);

    const toNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const toLastPage = () => {
        setCurrentPage(totalPages);
    };
    const toPreviousPage = () => {
        if (currentPage > START_PAGE) {
            setCurrentPage(currentPage - 1);
        }
    };
    const toFirstPage = () => {
        setCurrentPage(START_PAGE);
    };

    const { data } = GetTrainingCreditsReport(
        {filters: Object.entries(filters)?.reduce((prev, curr) => {
            if (!curr[1]?.isEditting && curr[1]?.value !== "") {
                return { ...prev, [curr[0]]: curr[1]?.value };
            }
            return prev;
        }, {}),
        setTotalPages,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        apiCallStatus,
        setApiCallStatus}
    );
   
 const exportFunc = (data) => {
        exportToExcelNew(data, headers, "training-credits-report");
    };
const excelData = GetTrainingCreditsReport({
        filters: {},
        apiCallStatus: apiCallStatus1,
        setApiCallStatus: setApiCallStatus1,
        setCurrentPage: () => {},
        setTotalPages: () => {},
        currentPage: 0,
        itemsPerPage: 0,
        isAll: 1,
    
        exportFunc,
    });
    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }


    const headers = [
        {
            label: "IBE #",
            filter: "ibe",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "WEEK #",
            filter: "week",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "IBE NAME",
            filter: "ibeName",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "CITY",
            filter: "ibeCity",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "STATE",
            filter: "ibeState",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "COUNTRY",
            filter: "ibeCountry",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        
        {
            label: "ENTRY LEVEL",
            filter: "entryLevel",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "DEDUCTED AMT",
            filter: "deducted_amt",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "CREDITED AMT",
            filter: "credited_amt",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "TOTAL TC",
            filter: "total_tc",
            filterable: true,
            
        },
        {
            label: "AVAILABLE TC",
            filter: "available_tc",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
       
    ];
    const gotopage = (page) => {
        setCurrentPage(page);
        setApiCallStatus(true);
    }
   
    const table = data?.data && (
        <div
            className="report-table-container-flex"
            style={{ position: "relative", zIndex: "1" }}
        >
            <div className="report-table-container scrollbar-blue">
                <CommonTableComponent
                    headers={headers}
                    datas={data.data}
                    filters={filters}
                    firstElementSticky={true}
                    searchOption={true}
                    setFilters={setFilters}
                    onSearch={() => {
                        setApiCallStatus(true);
                    }}
                    isLoading={data?.isLoading}
                />
            </div>
        </div>
    );

    const pagination =!data?.isLoading&& data?.data ? (
        <div
            className="report-table-container-flex"
            style={{ position: "relative", zIndex: "1" }}
        >
            <div className="pagination-container">
                <TablePagination
                    startPage={START_PAGE}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    toNextPage={toNextPage}
                    toLastPage={toLastPage}
                    toPreviousPage={toPreviousPage}
                    toFirstPage={toFirstPage}
                    exportButton={true}
                    exportFunc={() => {
                        setApiCallStatus1(true);
                    }}
                    gotopage={gotopage}
                />
            </div>
        </div>
    ) : null;

    return (
        <div
            className="container-bg-design common-div-container"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <h1
                className="bebas-neue-regular common-div-header gta-b "
                style={{ position: "relative", zIndex: "1", margin: "0" }}
            >
                IBE TRAINING CREDITS  (TC) REPORT
            </h1>
            {table}
            {pagination} 
        </div>
    );
};

export default TrainScoreReport;
