import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { GetIbeCommissionStatusReport } from "../../services/additonalModules/additionalFeature";
const IbeCommissionStatusReport = () => {
    const { t } = useTranslation();
    const headers = [
        // { label: "SELLER IBE #", filter: "seller_ibe", filterable: true },
        // {
        //     label: "SELLER IBE NAME",
        //     filter: "seller_ibe_name",
        //     filterable: true,
        // },
        { label: "IBE #", filter: "ibe", filterable: true },
        { label: "WEEK #", filter: "week", filterable: true },
        { label: "WEEK PERIOD", filter: "week_period", filterable: true },
        { label: "IBE NAME", filter: "ibe_name", filterable: true },
        { label: "NET BONUS $", filter: "net_bonus_usd", filterable: true },
        { label: "NET BONUS ₹", filter: "net_bonus_inr", filterable: true },
        { label: "NET BONUS ৳", filter: "net_bonus_bdt", filterable: true },
        { label: "BANK NAME", filter: "bank_name", filterable: true },
        { label: "ACCOUNT #", filter: "account", filterable: true },
        { label: "IFSC/ROUTING/BANK CODE", filter: "ifsc", filterable: true },
        { label: "ACCOUNT NAME", filter: "account_name", filterable: true },
        { label: "TXN DATE", filter: "txn_date", filterable: true },
        { label: "TXN STATUS", filter: "txn_status", filterable: true },
        { label: "TXN ID", filter: "txn_id", filterable: true },
        { label: "AMT $", filter: "amt_usd", filterable: true },
        { label: "AMT ₹", filter: "amt_inr", filterable: true },
        { label: "AMT ৳", filter: "amt_bdt", filterable: true },
    ];

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [filters, setFilters] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const startPage = 1;
    const itemsPerPage = 10;
    const { data } = GetIbeCommissionStatusReport({
        filters: Object.entries(filters)?.reduce((prev, curr) => {
            if (!curr[1]?.isEditting && curr[1]?.value !== "") {
                return { ...prev, [curr[0]]: curr[1]?.value };
            }
            return prev;
        }, {}),
        apiCallStatus,
        setApiCallStatus,
        setCurrentPage,
        setTotalPages,
        currentPage,
        itemsPerPage,
    });

    const toNextPage = () => {
        // setIsFetchable(true);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
    };
    const gotopage = (page) => {
        setCurrentPage(page);
        setApiCallStatus(true);
    }
    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }
    return (
        <>
            <div className="report-Container">
                <div className="report-header">
                    IBE COMMISSION STATUS REPORT
                </div>
                <div className="report-table-container-flex">
                    <div
                        className="report-table-container scrollbar-blue"
                        // style={{ display: "flex", flexDirection: "column" }}
                    >
                        <CommonTableComponent
                            headers={headers}
                            datas={data?.data}
                            filters={filters}
                            firstElementSticky={true}
                            searchOption={true}
                            setFilters={setFilters}
                            onSearch={() => {
                                setApiCallStatus(true);
                            }}
                            isLoading={data?.isLoading}
                        />
                    </div>
                </div>
                <div className="report-table-container-flex">
                    <div className="pagination-container">
                        {data?.isLoading && data?.data && data?.data?.length !== 0 && (
                            <TablePagination
                                startPage={startPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={itemsPerPage}
                                toNextPage={toNextPage}
                                toLastPage={toLastPage}
                                toPreviousPage={toPreviousPage}
                                toFirstPage={toFirstPage}
                                exportButton={true}
                                gotopage={gotopage}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeCommissionStatusReport;
