import React, { useState } from "react";
import CheckoutPayment from "./EventPayment";
import anime from "animejs/lib/anime.es.js";
import { ApiHook } from "../../hooks/apiHook";
import EventDetails from "./EventDetails";

const EventList = ({
  setTotalAmount,
  totalAmount,
  userCountry,
  userData,
  conversionFactor,
  balance,
  eventList,
  events,
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [transPass, setTransPass] = useState("");
  const [selectedEvent, SetselectedEvent] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [errors, setErrors] = useState({});
  const eventPurchaseMutation = ApiHook.CallEventPurchaseMutation();

  const validateFields = () => {
    const newErrors = {};
    if (!selectedEvent) newErrors.event = "Event is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    eventPurchaseMutation.mutate(
      {
        selectedEvent,
        totalAmount,
        paymentMethod,
        transactionPassword: transPass,
      },
      {
        onSuccess: (res) => {
          if (res.status) {
            setTotalAmount(0);
            setPaymentMethod(null);
            setTransPass("");
            SetselectedEvent(null);
            setActiveStep(1);
            setErrors({});
          }
        },
      }
    );
  };
  const initiatePayment = async ({ payuDatas: datas, payuBaseUrl: url }) => {
    try {
      const form = document.createElement("form");
      form.action = url;
      form.method = "POST";

      for (let [key, value] of Object.entries(datas)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = value;
        form.appendChild(input);
      }

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };
  
  const handleNext = async () => {
    if (!validateFields()) return;

    if (totalAmount === 0) {
      await handleSubmit();
    } else {
      if (userCountry.toLowerCase() === "india" && !eventPurchaseMutation.isLoading && eventPurchaseMutation.status !== "success") {
        setPaymentMethod(9);

        eventPurchaseMutation.mutate(
          {
            selectedEvent,
            totalAmount: totalAmount,
            paymentMethod: 9,
          },
          {
            onSuccess: async (res) => {
              if (res.status) {
                await initiatePayment(res.data);
              }
            },
          }
        );
      } else if (userCountry.toLowerCase() === "bangladesh") {
        setPaymentMethod(2);
        const nextStep = activeStep + 1;
        animateStepTransition(nextStep);
        setActiveStep(nextStep);
      } else {
        setPaymentMethod(3);
        await handleSubmit();
      }
    }
  };

  const handleBack = () => {
    const prevStep = activeStep - 1;
    animateStepTransition(prevStep);
    setActiveStep(prevStep);
  };
  const animateStepTransition = () => {
    const containerElement = document.querySelector("#animation");
    // Set initial position of the next step
    anime.set(containerElement, { opacity: 0 });
    // Animate the next step in
    anime({
      targets: containerElement,
      opacity: [0, 1],
      duration: 1100,
      easing: "easeInOutQuad",
    });
  };
  return (
    <>
      <div className="change-password-form-container">
        <div
          style={{
            marginBottom: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: "2",
          }}
        >
          {activeStep === 1 && (
            <EventDetails
              setActiveStep={setActiveStep}
              handleSubmit={handleNext}
              eventList={eventList}
              events={events}
              selectedEvent={selectedEvent}
              SetselectedEvent={SetselectedEvent}
              conversionFactor={conversionFactor}
              balance={balance}
              setTotalAmount={setTotalAmount}
              errors={errors}
              setErrors={setErrors}
              totalAmount={totalAmount}
              eventPurchaseMutation={eventPurchaseMutation}
            />
          )}
          {activeStep === 2 && (
            <CheckoutPayment
              setActiveStep={setActiveStep}
              userData={userData}
              totalAmount={totalAmount}
              conversionFactor={conversionFactor}
              handleSubmit={handleSubmit}
              transPass={transPass}
              setTransPass={setTransPass}
              balance={balance}
              paymentMethod={paymentMethod}
              eventPurchaseMutation={eventPurchaseMutation}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EventList;
