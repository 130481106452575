import { Navigate } from "react-router-dom";
import MainDashboard from "../views/dashboard/MainDashboard";
import AuthLayout from "../Layouts/AuthLayout";
import EwalletLayout from "../views/ewallet/MainEwallet";
import PayoutLayout from "../views/payout/MainPayout";
import RegisterLayout from "../views/register/MainRegister";
import ProfileLayout from "../views/profile/MainProfile";
import GenealogyTree from "../views/tree/GenealogyTree";
import TreeView from "../views/tree/TreeView";
import EpinLayout from "../views/epin/MainEpin";
import ShoppingLayout from "../views/shopping/ShoppingLayout";
import ProductDetailsLayout from "../views/shopping/ProductDetailsLayout";
import CheckoutLayout from "../views/shopping/CheckoutLayout";
import Faqs from "../components/Tools/Faqs";
import News from "../components/Tools/News";
import DownlineMembers from "../views/tree/DownlineMembers";
import SponserTree from "../views/tree/SponserView";
import ReferralMembers from "../views/tree/ReferralMembers";
import Leads from "../views/Crm/Leads";
import ComingSoon from "../components/Common/ComingSoon";
import BackToOffice from "../components/Auth/BackToOffice";
import RegisterComplete from "../views/register/RegisterComplete";
import KycDetails from "../components/Profile/KycDetails";
import ReplicaSite from "../views/Tools/ReplicaSite";
import LeadsForm from "../components/Crm/LeadsForm";
import DownloadMaterials from "../views/Tools/DownloadMaterials";
import ReplicaLayout from "../Layouts/ReplicaLayout";
import MainReplica from "../views/replica/MainReplica";
import ReplicaRegisterLayout from "../views/replica/MainReplicaRegister";
import { ForgotPasswordForm } from "../components/Auth/forgotPassword";
import Upgrade from "../views/upgrade/Upgrade";
import Renew from "../views/renew/Renew";
import MailBox from "../views/mailbox/MailBox";
import RepurchaseReport from "../views/shopping/RepurchaseReportLayout";
import PurchaseInvoice from "../components/shopping/PurchaseInvoice";
import MainSupport from "../views/support/MainSupport";
import CreateTicket from "../components/Support/CreateTicket";
import SupportFaqs from "../components/Support/SupportFaqs";
import TicketDetails from "../components/Support/TicketDetails";
import TicketTimeline from "../components/Support/TicketTimeline";
import CrmDashboard from "../views/Crm/CrmDashboard";
import ViewLead from "../views/Crm/ViewLead";
import AddLead from "../views/Crm/AddLead";
import CrmGraph from "../views/Crm/CrmGraph";
import LeadsDetails from "../components/Crm/LeadDetails";
import LeadsHistory from "../components/Crm/LeadsHistory";
import GenealogyTreeWebView from "../views/web/GenealogyTreeWebView";
import SponserTreeWebView from "../views/web/SponserTreeWebView";
import WebToReact from "../components/Auth/WebToReact";
import TreeViewWeb from "../views/web/TreeViewWeb";
import WebView from "../components/Auth/WebView";
import VerifyEmailComponent from "../components/Auth/VerifyEmailComponent";
import PromotionTools from "../views/Tools/PromotionTools";
import DashLayout from "../Layouts/DashLayout";
import IbeOrderReport from "../views/reports/IbeOrderReport";
import IbeCustomerOrderReport from "../views/reports/IbeCustomerOrderReport";
import ChangePassword from "../components/Profile/ChangePassword";
import PreferredPassword from "../components/Profile/PreferredPassword";
import IbeCancelCustomerOrderReport from "../views/reports/IbeCancelCustomerOrderReport";
import IbeCancelOrderReport from "../views/reports/IbeCancelOrderReport";
import DeIbeReport from "../views/reports/DeIbeReport";
import DeCustomerReport from "../views/reports/DeCustomerReport";
import GenealogyTreeReport from "../views/reports/GenealogyTreeReport";
import IbeRankReport from "../views/reports/IbeRankReport";
import IbeTeamVolumeReport from "../views/reports/IbeTeamVolumeReport";
import IbeCommissionReport from "../views/reports/IbeCommissionReport";
import IbeCommissionStatusReport from "../views/reports/IbeCommissionStatusReport";
import TrainScoreReport from "../views/reports/TrainScoreReport";
import Nominee from "../views/Nominee/Nominee";
import Trip from "../views/Trip/Trip";
import ChangeTransactionPassword from "../components/Profile/ChangeTransactionPassword";
import AgentWalletRequest from "../views/agent/AgentWalletRequest";
import AgentWalletTransfer from "../views/agent/AgentWalletTrasfer";
import EventPurchase from "../views/eventPurchase/EventPurchase";
import IbeMbcDeliveryReport from "../views/stockpoint/IbeMbcDeliveryReport";
import IbeMbcInventoryReport from "../views/stockpoint/IbeMbcInventoryReport";
import IbeMbcDeliveryForm from "../views/stockpoint/IbeMbcDeliveryForm";
import IbeMbcCommissionReport from "../views/stockpoint/IbeMbcCommissionReport";
import MainReplicaStore from "../views/replica/MainReplicaStore";
import ReplicaCustomerRegisterLayout from "../views/replica/MainReplicaCustomerRegister";
import EventPurchaseComplete from "../views/eventPurchase/EventPurchaseComplete";
import CommentPage from "../views/support/Comment";
import RegisterCompleteNew from "../views/register/RegisterCompleteNew";

import TrainCreditsReport from "../views/reports/TrainCreditsReport";
const publicRoutes = [
    // {
    //     path: "/",
    //     element: <Navigate to="/login" replace />,
    // },
    {
        path: "/login/:adminUsername?/:username?",
        element: <AuthLayout />,
    },
    {
        path: "/oc-login/?:string?:db_prefix?",
        element: <BackToOffice />,
    },
    {
        path: "/lcp/:username?/:hash?",
        element: <LeadsForm />,
    },
    {
        path: "/replica/:username",
        element: (
            // <ReplicaLayout replicaStatus={true}>
            <MainReplica />
            // </ReplicaLayout>
        ),
    },
    // {
    //     path: "/store/:username",
    //     element: (
    //         // <ReplicaLayout replicaStatus={true}>
    //         <MainReplicaStore />
    //         // </ReplicaLayout>
    //     ),
    // },
    // {
    //     path: "/store-register/:username",
    //     element: (
    //         // <ReplicaLayout replicaStatus={true}>
    //         <ReplicaCustomerRegisterLayout />
    //         // </ReplicaLayout>
    //     ),
    // },
    {
        path: "/replica-register/:username",
        element: (
            // <ReplicaLayout>
            <ReplicaRegisterLayout />
            // </ReplicaLayout>
        ),
    },
    {
        path: "/confirm_email/:encryptedAdminUsername?/:encryptedUsername",
        element: <VerifyEmailComponent />,
    },
    {
        path: "/register-preview/:username",
        element: <RegisterComplete />,
    },
    {
        path: "/register-complete/:username",
        element: <RegisterCompleteNew />,
    },
];

const privateRoutes = [
    // {
    //     path: "/dashboard",
    //     element: <DashLayout />,
    // },
    {
        path: "/networks",
        element: <Navigate to="/genealogy-tree" replace />,
    },
    {
        path: "/e-wallet",
        element: <EwalletLayout />,
    },
    {
        path: "/e-pin",
        element: <EpinLayout />,
    },
    {
        path: "/payout",
        element: <PayoutLayout />,
    },
    {
        path: "/genealogy-tree",
        element: <GenealogyTree />,
    },
    {
        path: "/sponsor-tree",
        element: <SponserTree />,
    },
    {
        path: "/tree-view",
        element: <TreeView />,
    },
    {
        path: "/downline-members",
        element: <DownlineMembers />,
    },
    {
        path: "/referral-members",
        element: <ReferralMembers />,
    },
    // {
    //     path: "/register/:parent?/:position?",
    //     element: <RegisterLayout />,
    // },
    {
        path: "/profile/:activeTab?",
        element: <ProfileLayout />,
    },
    {
        path: "/shopping",
        element: <ShoppingLayout />,
    },
    {
        path: "/shopping/:type",
        element: <ShoppingLayout />,
    },
    {
        path: "/shopping/:type/:courier",
        element: <ShoppingLayout />,
    },
    {
        path: "/product-details/:id",
        element: <ProductDetailsLayout />,
    },
    {
        path: "/checkout",
        element: <CheckoutLayout />,
    },
    {
        path: "/checkout/:type/:courier",
        element: <CheckoutLayout />,
    },
    {
        path: "/faqs",
        element: <Faqs />,
    },
    {
        path: "/news/:newsId?",
        element: <News />,
    },
    {
        path: "/leads",
        element: <Leads />,
    },
    {
        path: "/settings",
        element: <ComingSoon />,
    },
    {
        path: "/downloads",
        element: <ComingSoon />,
    },
    {
        path: "/mailbox",
        element: <MailBox />,
    },
    {
        path: "/support-center",
        element: <MainSupport />,
    },
    {
        path: "/download-materials",
        element: <DownloadMaterials />,
    },
    {
        path: "/replica-site",
        element: <ReplicaSite />,
    },
    {
        path: "/promotion-tools",
        element: <PromotionTools />,
    },
    {
        path: "/registration-complete/:username?",
        element: <RegisterComplete />,
    },
    {
        path: "/kyc-details",
        element: <KycDetails />,
    },
    {
        path: "/upgrade",
        element: <Upgrade />,
    },
    {
        path: "/renew",
        element: <Renew />,
    },
    {
        path: "/repurchase-report",
        element: <RepurchaseReport />,
    },
    {
        path: "/invoice/:type/:id",
        element: <PurchaseInvoice />,
    },
    {
        path: "/create-ticket",
        element: <CreateTicket />,
    },
    {
        path: "/support-faqs",
        element: <SupportFaqs />,
    },
    {
        path: "/ticket-details/:trackId?",
        element: <TicketDetails />,
    },
    {
        path: "/ticket-timeline/:trackId?",
        element: <TicketTimeline />,
    },
    {
        path: "/crm-dashboard",
        element: <CrmDashboard />,
    },
    {
        path: "/view-lead",
        element: <ViewLead />,
    },
    {
        path: "/add-lead",
        element: <AddLead />,
    },
    {
        path: "/crm-graph",
        element: <CrmGraph />,
    },
    {
        path: "/crm-timeline/:id?",
        element: <LeadsDetails />,
    },
    {
        path: "/crm-lead-history/:id?",
        element: <LeadsHistory />,
    },
    {
        path: "/ibe-order-reports",
        element: <IbeOrderReport />,
    },
    {
        path: "/ibe-customer-order-reports",
        element: <IbeCustomerOrderReport />,
    },
    {
        path: "/ibe-cancel-orders-report",
        element: <IbeCancelOrderReport />,
    },
    {
        path: "/ibe-cancel-customer-orders-report",
        element: <IbeCancelCustomerOrderReport />,
    },
    {
        path: "/change_password",
        element: <ChangePassword />,
    },
    {
        path: "/change-trans-password",
        element: <ChangeTransactionPassword />,
    },
    {
        path: "/preferred_placement",
        element: <PreferredPassword />,
    },
    {
        path: "/de_ibe_report",
        element: <DeIbeReport />,
    },
    {
        path: "/de_customer_report",
        element: <DeCustomerReport />,
    },
    {
        path: "/dual_team_genealogy_report",
        element: <GenealogyTreeReport />,
    },

    {
        path: "/ibe_live_rank_report",
        element: <IbeRankReport />,
    },
    {
        path: "/ibe_team_volume_report",
        element: <IbeTeamVolumeReport />,
    },
    {
        path: "/ibe_commission_report",
        element: <IbeCommissionReport />,
    },
    {
        path: "/ibe_commission_status_report",
        element: <IbeCommissionStatusReport />,
    },
    {
        path: "/training_score_report",
        element: <TrainScoreReport />,
    },
    {
        path: "/tc_report",
        element: <TrainCreditsReport />,
    },
    {
        // path: "/register/:parent?/:position?",
        // element: <RegisterLayout />,
    },
    {
        path: "/nominee",
        element: <Nominee />,
    },
    {
        path: "/upload_exotic_trip_docs",
        element: <Trip />,
    },
    {
        path: "/agent_ibe_wallet_credit_report",
        element: <IbeOrderReport />,
    },
    {
        path: "/agent_ibe_wallet_credit_request",
        element: <AgentWalletRequest />,
    },
    {
        path: "/agent_ibe_wallet_credit_transfer",
        element: <AgentWalletTransfer />,
    },
    {
        path: "/event-purchase",
        element: <EventPurchase />,
    },

    {
        path: "/ibe_mbc_inventory_report",
        element: <IbeMbcInventoryReport />,
    },
    {
        path: "/ibe_mbc_delivery",
        element: <IbeMbcDeliveryForm />,
    },
    {
        path: "/ibe_mbc_delivery_report",
        element: <IbeMbcDeliveryReport />,
    },
    {
        path: "/ibe_mbc_commission_report",
        element: <IbeMbcCommissionReport />,
    },
    {
        path: "/event-purchase-complete/:id",
        element: <EventPurchaseComplete />,
    },
    {
        path: "/comment/:ticketId",
        element: <CommentPage />,
    },
];

const webRoutes = [
    {
        path: "/genealogy-tree-web/",
        element: <GenealogyTreeWebView />,
    },
    {
        path: "/sponsor-tree-web/",
        element: <SponserTreeWebView />,
    },
    {
        path: "/tree-view-web",
        element: <TreeViewWeb />,
    },
    {
        path: "/web-login/?:string?:db_prefix:type?",
        element: <WebView />,
    },
];

export { privateRoutes, publicRoutes, webRoutes };
