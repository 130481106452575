import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { formatDateWithoutTime } from "../../utils/formateDate";
import CommonInput from "../../components/Common/CommonInputComponent";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { error } from "jquery";
const EventDetails = ({
    setActiveStep,
    handleSubmit,
    eventList,
    events,
    selectedEvent,
    SetselectedEvent,
    conversionFactor,
    balance,
    setTotalAmount,
    errors,
    setErrors,
    totalAmount,
    eventPurchaseMutation
}) => {
    //   const [eventDetails ,setEventDetails]  =useState(null) ;
    const backgroundColor = "#e4f0f2";
    let balanceAmt = 0;
    const handleChange = async (value) => {
        eventList.map((i) => {
            if (i.eventName === value) {
                SetselectedEvent(i);
                setErrors((prev) => ({
                    ...prev,
                    event: null,
                }));

                balanceAmt = i.amount > balance ? i.amount - balance : 0;
                setTotalAmount(balanceAmt);
            }
        });
    };
    console.log(eventPurchaseMutation,"eventPurchaseMutationeventPurchaseMutation");
    return (
        <>
            <div className="register-verification-container">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className="register-verification-container">
                        <CommonInput
                            type="text"
                            label="Available TC"
                            backgroundColor={backgroundColor}
                            value={`${
                                conversionFactor?.symbolLeft ?? "$"
                            } ${CustomCurrencyConverter(
                                balance,
                                conversionFactor?.value
                            )}`}
                            defaultValue=""
                            disabled={true}
                        />

                        <CommonInput
                            type="select"
                            label="Events"
                            backgroundColor={backgroundColor}
                            value={selectedEvent?.eventName ?? ""}
                            onChange={(e) => {
                                handleChange(e.target.value);
                            }}
                            options={events}
                            defaultValue=""
                            isValidationEnabled={true}
                        />
                        {/* <CommonInput
                            type="text"
                            label="PAYABLE AMOUNT"
                            backgroundColor={backgroundColor}
                            value={`${
                                conversionFactor?.symbolLeft ?? "$"
                            } ${CustomCurrencyConverter(
                                totalAmount,
                                conversionFactor?.value
                            )}`}
                            defaultValue=""
                            disabled={true}
                        /> */}
                        {selectedEvent && (
                            <div>
                                <h3>EVENT BANNER</h3>
                                <div>
                                    <img
                                        style={{
                                            width: "100%",
                                            borderRadius: "10px",
                                            // height: "400px",
                                            // marginRight: "20px",
                                        }}
                                        src={selectedEvent.banner}
                                        alt="banner"
                                    />
                                    <h5 style={{ marginTop: "10px" }}>
                                        {" "}
                                        TICKET PRICE :{" "}
                                        {`${
                                            conversionFactor?.symbolLeft ?? "$"
                                        } ${CustomCurrencyConverter(
                                            selectedEvent.amount ?? 0,
                                            conversionFactor?.value
                                        )}`}
                                    </h5>
                                    <h5 style={{ marginTop: "10px" }}>
                                        {" "}
                                        PAYABLE AMOUNT :{" "}
                                        {`${
                                            conversionFactor?.symbolLeft ?? "$"
                                        } ${CustomCurrencyConverter(
                                            totalAmount,
                                            conversionFactor?.value
                                        )}`}
                                    </h5>
                                </div>
                            </div>
                        )}
                        {errors?.event && <p>{errors.event}</p>}
                        <div
                            className="cart-checkout-button-container "
                            style={{ display: "block" }}
                        >
                            <button
                                onClick={handleSubmit}
                                style={{ width: "auto" }}
                                className='w-100 mt-3 common-button'
                                disabled={
                                    eventPurchaseMutation.isLoading || eventPurchaseMutation.status === "success"
                                  }
                            >
                                PROCEED CHECKOUT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventDetails;
