import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { GetGenealogyTreeReport } from "../../services/additonalModules/additionalFeature";
import { exportToExcelNew } from "../../utils/tableExports";
const GenealogyTreeReport = () => {
    const { t } = useTranslation();

    const statusColorLookUp = {
        Active: { backgroundColor: "#e4f0f2", color: "#5ba5b8" },
        Verified: { backgroundColor: "#e4f0f2", color: "#5ba5b8" },
        Inactive: { backgroundColor: "#c8cfb7", color: "#89a65f" },
        Terminated: { backgroundColor: "#FEE4D1", color: "#9B5731" },
        "Not Verified": { backgroundColor: "#FEE4D1", color: "#9B5731" },
        Expired: { backgroundColor: "#FEE4D1", color: "#9B5731" },
    };

    const headers = [
        { label: "IBE #", filter: "ibe", filterable: true },
        { label: "IBE NAME", filter: "ibe_name", filterable: true },
        {
            label: "IBE STATUS",
            filter: "ibe_status",
            filterable: true,
            render: (el) => {
                let color = statusColorLookUp[el]?.color;
                let backgroundColor = statusColorLookUp[el]?.backgroundColor;

                return (
                    <div
                        className="table_status_active"
                        style={{
                            backgroundColor,
                            // color,
                        }}
                    >
                        {el}
                    </div>
                );
            },
        },
        { label: "ENTRY LEVEL", filter: "entry_level", filterable: true },
        { label: "PLACEMENT", filter: "placement", filterable: true },
        { label: "LEVEL", filter: "level", filterable: true },
        { label: "SPONSOR IBE #", filter: "sponsor_ibe", filterable: true },
        { label: "SPONSOR NAME", filter: "sponsor_name", filterable: true },
        {
            label: "JOIN DATE",
            filter: "join_date",
            filterable: true,
            type: "date",
        },
        { label: "LIVE RANK", filter: "live_rank", filterable: true },
        {
            label: "LIVE COMMISSION RANK",
            filter: "commission_rank",
            filterable: true,
        },
        { label: "HIGHEST RANK", filter: "highest_rank", filterable: true },
        { label: "CITY", filter: "city", filterable: true },
        { label: "STATE", filter: "state", filterable: true },
        { label: "COUNTRY", filter: "country", filterable: true },
        { label: "PSV", filter: "psv", filterable: true },
        { label: "QSV", filter: "qsv", filterable: true },
        { label: "LETSV", filter: "letsv", filterable: true },
        { label: "RITSV", filter: "ritsv", filterable: true },
        { label: "LFTSV", filter: "lftsv", filterable: true },
        { label: "RFTSV", filter: "rftsv", filterable: true },
        {
            label: "LAST ORD DATE",
            filter: "last_ord_date",
            filterable: true,
            type: "date",
        },
        {
            label: "LAST ORD BEFORE",
            filter: "last_ord_before",
            filterable: true,
        },
        { label: "TC", filter: "tc", filterable: true },
        { label: "PTS", filter: "TS", filterable: true },
        {
            label: "KYC STATUS",
            filter: "kyc_status",
            filterable: true,
            render: (el) => {
                let color = statusColorLookUp[el]?.color;
                let backgroundColor = statusColorLookUp[el]?.backgroundColor;

                return (
                    <div
                    className="table_status_active"
                        style={{
                            backgroundColor,
                            // color,
                        }}

                        
                    >
                        {el}
                    </div>
                );
            },
        },
    ];

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [apiCallStatus1, setApiCallStatus1] = useState(false);
    const [filters, setFilters] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const startPage = 1;
    const itemsPerPage = 10;

    const exportFunc = async (data) => {
        exportToExcelNew(data, headers, "ibe-genology-tree-report");
    };

    const { data, isLoading } = GetGenealogyTreeReport({
        filters: Object.entries(filters)?.reduce((prev, curr) => {
            if (!curr[1]?.isEditting && curr[1]?.value !== "") {
                return { ...prev, [curr[0]]: curr[1]?.value };
            }
            return prev;
        }, {}),
        apiCallStatus,
        setApiCallStatus,
        setCurrentPage,
        setTotalPages,
        currentPage,
        itemsPerPage,
    });

    const excelData = GetGenealogyTreeReport({
        filters: {},
        apiCallStatus: apiCallStatus1,
        setApiCallStatus: setApiCallStatus1,
        setCurrentPage: () => {},
        setTotalPages: () => {},
        currentPage: 0,
        itemsPerPage: 0,
        isAll: 1,
        exportFunc,
    });

    const toNextPage = () => {
        // setIsFetchable(true);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            setApiCallStatus(true);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
        setApiCallStatus(true);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
            setApiCallStatus(true);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
        setApiCallStatus(true);
    };

    const gotoPage = (page) => {
        setCurrentPage(page);
        setApiCallStatus(true);
    }

    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }
    return (
        <>
            <div className="report-Container">
                <div className="report-header"> DUAL TEAM GENEALOGY REPORT</div>
                <div className="report-table-container-flex">
                    <div
                        className="report-table-container scrollbar-blue"
                        // style={{ display: "flex", flexDirection: "column" }}
                        
                    >
                        <CommonTableComponent
                            headers={headers}
                            datas={data?.data ?? []}
                            filters={filters}
                            firstElementSticky={true}
                            searchOption={true}
                            setFilters={setFilters}
                            onSearch={() => {
                                setApiCallStatus(true);
                            }}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                <div className="report-table-container-flex">
                    <div className="pagination-container">
                        {!data?.isLoading &&  data && data?.data?.length !== 0 && (
                            <TablePagination
                                startPage={startPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={itemsPerPage}
                                toNextPage={toNextPage}
                                toLastPage={toLastPage}
                                toPreviousPage={toPreviousPage}
                                toFirstPage={toFirstPage}
                                exportButton={true}
                                exportFunc={() => {
                                    setApiCallStatus1(1);
                                }}
                                gotoPage={gotoPage}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default GenealogyTreeReport;
