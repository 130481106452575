import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import { exportToExcelNew } from "../../utils/tableExports";
const IbeRankReport = () => {
    const { t } = useTranslation();
    const headers = [
        { label: "IBE #", filter: "ibe", filterable: true },
        { label: "IBE NAME", filter: "ibe_name", filterable: true },
        { label: "WEEK #", filter: "week", filterable: true },
        { label: "WEEK PERIOD", filter: "weekPeriod", filterable: true },

        { label: "IE <br/> LAFF", filter: "ie_laff", filterable: true },
        { label: "IE <br/> RAFF", filter: "ie_raff", filterable: true },
        { label: "DE <br/> LAFF", filter: "de_laff", filterable: true },
        { label: "DE <br/> RAFF", filter: "de_raff", filterable: true },


        { label: "IE <br/> LA", filter: "ie_la", filterable: true },
        { label: "IE <br/> RA", filter: "ie_ra", filterable: true },
        { label: "IE <br/> LB", filter: "ie_lb", filterable: true },
        { label: "IE <br/> RB", filter: "ie_rb", filterable: true },
        { label: "IE <br/> LC", filter: "ie_lc", filterable: true },
        { label: "IE <br/> RC", filter: "ie_rc", filterable: true },
        { label: "IE <br/> LD", filter: "ie_ld", filterable: true },
        { label: "IE <br/> RD", filter: "ie_rd", filterable: true },
        { label: "IE <br/> LE", filter: "ie_le", filterable: true },
        { label: "IE <br/> RE", filter: "ie_re", filterable: true },
        { label: "IE <br/> LF", filter: "ie_lf", filterable: true },
        { label: "IE <br/> RF", filter: "ie_rf", filterable: true },
        { label: "DE <br/> LG", filter: "de_lg", filterable: true },
        { label: "DE <br/> RG", filter: "de_rg", filterable: true },
        { label: "DE <br/> LH", filter: "de_lh", filterable: true },
        { label: "DE <br/> RH", filter: "de_rh", filterable: true },
        { label: "DE <br/> LI", filter: "de_li", filterable: true },
        { label: "DE <br/> RI", filter: "de_ri", filterable: true },
        { label: "DE <br/> LJ", filter: "de_lj", filterable: true },
        { label: "DE <br/> RJ", filter: "de_rj", filterable: true },
        { label: "IE <br/> LG", filter: "ie_lg", filterable: true },
        { label: "IE <br/> RG", filter: "ie_rg", filterable: true },
        { label: "IE <br/> LH", filter: "ie_lh", filterable: true },
        { label: "IE <br/> RH", filter: "ie_rh", filterable: true },
        { label: "IE <br/> LI", filter: "ie_li", filterable: true },
        { label: "IE <br/> RI", filter: "ie_ri", filterable: true },
        { label: "IE <br/> LJ", filter: "ie_lj", filterable: true },
        { label: "IE <br/> RJ", filter: "ie_rj", filterable: true },
    ];

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [apiCallStatus1, setApiCallStatus1] = useState(false);
    const [filters, setFilters] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const datas = [];
    const startPage = 1;
    const itemsPerPage = 10;

    const ibeLiveRankReport = ApiHook.CallgetIbeLiveRankReport({
        filters: Object.entries(filters)?.reduce((prev, curr) => {
            if (!curr[1]?.isEditting && curr[1]?.value !== "") {
                return { ...prev, [curr[0]]: curr[1]?.value };
            }
            return prev;
        }, {}),
        apiCallStatus,
        setApiCallStatus,
        setCurrentPage,
        setTotalPages,
        currentPage,
    });
    
    const exportFunc = (data) => {
        exportToExcelNew(data, headers, "ibe-live-rank-report");
    };
    const excelData = ApiHook.CallgetIbeLiveRankReport({
        filters: {},
        apiCallStatus: apiCallStatus1,
        setApiCallStatus: setApiCallStatus1,
        setCurrentPage: () => {},
        setTotalPages: () => {},
        currentPage: 0,
        isAll: 1,
        exportFunc,
    });
    const toNextPage = () => {
        // setIsFetchable(true);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
    };

    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }
    const gotopage = (page) => {
        setCurrentPage(page);
        setApiCallStatus(true);
    }
    return (
        <>
            <div className="report-Container">
                <div className="report-header"> IBE LIVE RANK REPORT</div>
                <div className="report-table-container-flex">
                    <div
                        className="report-table-container scrollbar-blue"
                        // style={{ display: "flex", flexDirection: "column" }}
                    >
                        <CommonTableComponent
                            headers={headers}
                            datas={ibeLiveRankReport?.data?.data}
                            filters={filters}
                            firstElementSticky={true}
                            searchOption={true}
                            setFilters={setFilters}
                            onSearch={() => {
                                setApiCallStatus(true);
                            }}
                            isLoading={ibeLiveRankReport?.isLoading}
                        />
                    </div>
                </div>
                <div className="report-table-container-flex">
                    <div className="pagination-container">
                        {!ibeLiveRankReport?.isLoading && datas && datas?.length !== 0 && (
                            <TablePagination
                                startPage={startPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={itemsPerPage}
                                toNextPage={toNextPage}
                                toLastPage={toLastPage}
                                toPreviousPage={toPreviousPage}
                                toFirstPage={toFirstPage}
                                exportButton={true}
                                exportFunc={() => {
                                    setApiCallStatus1(true);
                                }}
                                gotopage={gotopage}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeRankReport;
