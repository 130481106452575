import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { GetTrainingScoreReport } from "../../services/additonalModules/additionalFeature";
import moment from "moment";
import { exportToExcelNew } from "../../utils/tableExports";
const TrainScoreReport = () => {
    const { t } = useTranslation();

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [filters, setFilters] = useState({});

    const START_PAGE = 1;

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(10);

    const toNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const toLastPage = () => {
        setCurrentPage(totalPages);
    };
    const toPreviousPage = () => {
        if (currentPage > START_PAGE) {
            setCurrentPage(currentPage - 1);
        }
    };
    const toFirstPage = () => {
        setCurrentPage(START_PAGE);
    };

    const { data } = GetTrainingScoreReport(
        setTotalPages,
        currentPage,
        itemsPerPage,
        apiCallStatus,
        setApiCallStatus
    );

    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }

    // const statusColorLookUp = {
    //     Open: { backgroundColor: "#E4F0F2", color: "#2E8CA5" },
    //     Closed: { backgroundColor: "#FEE4D1", color: "#9B5731" },
    // };

    const statusColorLookUp = {
        Active: { backgroundColor: "#e4f0f2", color: "#5ba5b8" },
        Verified: { backgroundColor: "#e4f0f2", color: "#5ba5b8" },
        Inactive: { backgroundColor: "#c8cfb7", color: "#89a65f" },
        Terminated: { backgroundColor: "#FEE4D1", color: "#9B5731" },
        "Not Verified": { backgroundColor: "#FEE4D1", color: "#9B5731" },
        Expired: { backgroundColor: "#FEE4D1", color: "#9B5731" },
    };

    const headers = [
        {
            label: "IBE #",
            filter: "ibe",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "IBE NAME",
            filter: "ibeName",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "CITY",
            filter: "ibeCity",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "STATE",
            filter: "ibeState",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "COUNTRY",
            filter: "ibeCountry",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "IBE STATUS",
            filter: "status",
            filterable: true,
            render: (el) => {
                let color = statusColorLookUp[el]?.color;
                let backgroundColor = statusColorLookUp[el]?.backgroundColor;

                return (
                    <div
                    className="table_status_active"
                        style={{
                            backgroundColor,
                            // color,
                        }}
                    >
                        {el}
                    </div>
                );
            },
        },
        {
            label: "ENTRY LEVEL",
            filter: "entryLevel",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "EVENT NAME",
            filter: "name",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "EVENT DATE",
            filter: "dated",
            filterable: true,
            
        },
        {
            label: "EVENT LOCATION",
            filter: "location",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "EVENT TS",
            filter: "eventTs",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "CUMULATIVE TS ",
            filter: "totalTs",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "PTS ",
            filter: "pts",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
    ];
    const gotopage = (page) => {
        setCurrentPage(page);
        setApiCallStatus(true);
    }
    const exportFunc = () => {
        exportToExcelNew(data.eventData, headers, "training-score-report");
    };

    const table = data?.eventData && (
        <div
            className="report-table-container-flex"
            style={{ position: "relative", zIndex: "1" }}
        >
            <div className="report-table-container scrollbar-blue">
                <CommonTableComponent
                    headers={headers}
                    datas={data.eventData}
                    filters={filters}
                    firstElementSticky={true}
                    searchOption={true}
                    setFilters={setFilters}
                    onSearch={() => {
                        setApiCallStatus(true);
                    }}
                    isLoading={data?.isLoading}
                />
            </div>
        </div>
    );

    const pagination =!data?.isLoading&& data?.eventData ? (
        <div
            className="report-table-container-flex"
            style={{ position: "relative", zIndex: "1" }}
        >
            <div className="pagination-container">
                <TablePagination
                    startPage={START_PAGE}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    toNextPage={toNextPage}
                    toLastPage={toLastPage}
                    toPreviousPage={toPreviousPage}
                    toFirstPage={toFirstPage}
                    exportButton={true}
                    exportFunc={exportFunc}
                    gotopage={gotopage}
                />
            </div>
        </div>
    ) : null;

    return (
        <div
            className="container-bg-design common-div-container"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <h1
                className="bebas-neue-regular common-div-header gta-b "
                style={{ position: "relative", zIndex: "1", margin: "0" }}
            >
                IBE TRAINING SCORE  (TS) REPORT
            </h1>
            {table}
            {pagination} 
        </div>
    );
};

export default TrainScoreReport;
