import React, { useState } from "react";
import moment from "moment";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { GetDirectEnrolmentReport } from "../../services/additonalModules/additionalFeature";
import { exportToExcelNew } from "../../utils/tableExports";

const statusColorLookUp = {
    active: { backgroundColor: "#c7e6d3", color: "#66bc7e" },
    inactive: { backgroundColor: "#E4F0F2", color: "#2E8CA5" },
    expired: { backgroundColor: "#FEE4D1", color: "#9B5731" },
    terminated: { backgroundColor: "#FEE4D1", color: "#9B5731" },
};

const headers = [
    { label: "IBE #", filter: "ibe", filterable: true },
    { label: "IBE NAME", filter: "ibeName", filterable: true },
    { label: "PLACEMENT", filter: "placement", filterable: true },
    {
        label: "JOIN DATE",
        filter: "joinDate",
        filterable: true,
        render: (el) => <span>{el}</span>,
    },
    { label: "LIVE RANK", filter: "liveRank", filterable: true },
    {
        label: "LIVE COMMISSION RANK",
        filter: "commissionRank",
        filterable: true,
    },
    { label: "PHONE", filter: "phone", filterable: true },
    { label: "EMAIL", filter: "email", filterable: true },
    { label: "CITY", filter: "city", filterable: true },
    { label: "STATE", filter: "state", filterable: true },
    { label: "COUNTRY", filter: "country", filterable: true },
    {
        label: "STATUS",
        filter: "status",
        filterable: true,
        render: (el) => {
            let color = statusColorLookUp[el]?.color;
            let backgroundColor = statusColorLookUp[el]?.backgroundColor;

            return (
                <div
                className="table_status_active"
                    style={{
                        backgroundColor,
                        // color,
                    }}
                >
                    {el}
                </div>
            );
        },
    },
];

const DeIbeReport = () => {
    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [apiCallStatus1, setApiCallStatus1] = useState(false);
    const [filters, setFilters] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const exportFunc = (data) => {
        exportToExcelNew(data, headers, "ibe-de-report");
    };

    const startPage = 1;
    const itemsPerPage = 10;
    const toNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
    };
    const gotopage = (page) => {
        setCurrentPage(page);
        setApiCallStatus(true);
    }
    const { data } = GetDirectEnrolmentReport({
        setTotalPages,
        page: currentPage,
        itemsPerPage,
        apiCallStatus,
        setApiCallStatus,
        filters,
    });
    // const excelData = GetDirectEnrolmentReport({
    //     setTotalPages: () => {},
    //     page: currentPage,
    //     itemsPerPage,
    //     apiCallStatus: apiCallStatus1,
    //     setApiCallStatus: setApiCallStatus1,
    //     filters: {},
    //     isAll: 1,
    //     exportFunc,
    //     name: "test",
    // });
    const excelData = GetDirectEnrolmentReport({
        filters: {},
        apiCallStatus: apiCallStatus1,
        setApiCallStatus: setApiCallStatus1,
        setCurrentPage: () => {},
        setTotalPages: () => {},
        currentPage: 0,
        itemsPerPage: 0,
        isAll: 1,
        exportFunc,
    });

    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }

    const table = data?.data && (
        <div className="report-table-container-flex">
            <div className="report-table-container scrollbar-blue">
                <CommonTableComponent
                    headers={headers}
                    datas={data?.data}
                    filters={filters}
                    firstElementSticky={true}
                    searchOption={true}
                    setFilters={setFilters}
                    onSearch={() => {
                        setApiCallStatus(true);
                    }}
                    isLoading={data?.isLoading}
                />
            </div>
        </div>
    );

    const pagination = !data?.isLoading && data?.data ? (
        <div className="report-table-container-flex">
            <div className="pagination-container">
                <TablePagination
                    startPage={startPage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    toNextPage={toNextPage}
                    toLastPage={toLastPage}
                    toPreviousPage={toPreviousPage}
                    toFirstPage={toFirstPage}
                    exportButton={true}
                    exportFunc={() => {
                        setApiCallStatus1(true);
                    }}
                    gotopage={gotopage}
                />
            </div>
        </div>
    ) : null;

    return (
        <div
            className="container-bg-design common-div-container"
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <h1
               className="report-header"
            >
                IBE DIRECT ENROLMENT REPORT
            </h1>
            {table}
            {pagination}
        </div>
    );
};

export default DeIbeReport;
