import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
// import { formatDate } from "../../utils/formateDate";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useReactToPrint } from "react-to-print";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";
import logo_user from "../../assests/images/invoiceLogo.png";
import { useSearchParams } from "react-router-dom";

const RegisterCompleteNew = () => {
    const { t } = useTranslation();
    const tableRef = useRef();
    const [conversionFactor, setConversionFactor] = useState();
    const navigate = useNavigate();

    const userSelectedCurrency = useSelector(
        (state) => state?.user?.selectedCurrency
    );

    const [successMessageShow, setSuccessMessageShow] = useState(true);
    const params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const isSuccess = searchParams.get("success");
    const username = params?.username;
    const preview = ApiHook.CallLetterPreview(username);
    const user = preview?.data?.userData;
    const order = preview?.data?.orderData;

    const closeAlert = () => {
        setSuccessMessageShow(false);
    };
    // useEffect(() => {
    //   setConversionFactor(
    //     preview?.data?.userCountry.toLowerCase() === "india"
    //       ? {
    //           symbolLeft: preview?.data?.activeCurrencies["INR"]?.symbol,
    //           value: preview?.data?.activeCurrencies["INR"]?.value,
    //         }
    //       : preview?.data?.userCountry.toLowerCase() === "bangladesh"
    //       ? {
    //           symbolLeft: preview?.data?.activeCurrencies["BDT"]?.symbol,
    //           value: preview?.data?.activeCurrencies["BDT"]?.value,
    //         }
    //       : {
    //           symbolLeft: preview?.data?.activeCurrencies["USD"]?.symbol,
    //           value: preview?.data?.activeCurrencies["USD"]?.value,
    //         }
    //   );
    // }, [preview?.data]);

    // Use useEffect to automatically call closeAlert after 3 seconds
    const handlenavigation = () => {
        navigate("/login");
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            closeAlert();
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, [successMessageShow]);

    // const handlePrint = useReactToPrint({
    //   content: () => tableRef.current,
    //   documentTitle: `Invoice-${order?.invoiceNo}`,
    //   removeAfterPrint: true,
    // });

    return (
        <div style={{ height: "100dvh" }}>
            {/* <div className="page_head_top">{t("purchase_invoice")}</div> */}
            <div className="container-bg-design" style={{ height: "100vh" }}>
                {/* {isSuccess && ( */}
                <div className="succuss-padding">
                    <div className="register-invoice-succuss">
                        {/* <img
              className="succuss_image"
              src="/images/succuss-check.svg"
              alt=""
            />{" "} */}
                        {/* <span className="invoice-succuss_msg"> */}
                        <div style={{ maxWidth: "100%", padding: "10px" }}>
                            <h3 className="common-div-header gta-b pt-0 mb-3">
                                REGISTRATION COMPLETED
                            </h3>
                            <span
                                style={{
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    color: "black",
                                }}
                            >
                                {`Thank You! ${user?.UserDetail?.name ?? ""} ${
                                    user?.UserDetail?.secondName ?? ""
                                }, your order has been confirmed.
                                `}
                                <br />
                                {` Your Order Number is`}{" "}
                                <label className="fs-md-14 bol-3">
                                    {order?.invoiceNo}.
                                </label>
                                <span className="fs-md-14">
                                    <br />
                                    {`An Independent Brand Executive account has been created for you at www.moringobioscience.com.`}
                                </span>
                                <br />
                                <span className="fs-md-14">
                                    {`Expect to receive a confirmation via SMS and email shortly.`}
                                </span>
                            </span>
                            <button
                                className="common-button registation d-block"
                                onClick={() => handlenavigation()}
                            >
                                LOGIN
                            </button>
                        </div>
                    </div>
                </div>
                {/* )} */}
                {/* <div className="modal bs-example-modal-xl invoice-modal" ref={tableRef}>
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header justify-content-end">
                <div className="d-flex gap-3">
                  <button className="print-button_modal" onClick={handlePrint}>
                    <i className="fa fa-print" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="d-flex justify-content-lg-start justify-content-center print-logo">
                        <img
                          className="modal_invoice-logo"
                          src={logo_user}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="text-lg-end text-center d-flex justify-content-lg-end justify-content-center print-incoice-head">
                        <div
                          style={{
                            justifySelf: "flex-end",
                          }}
                        >
                          <p className="heading_sm_tax-invoice">
                            Original for Receipient
                          </p>
                          <h5 className="heading_tax-invoice mb-0">
                            TAX INVOICE
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row invoice_row">
                    <div className="col-lg-6">
                      <div className="invoice_border-Box">
                        <div className="invoice-items">
                          <span className="invoice_text_strong d-block">
                            {preview?.data?.companyDetails?.name ??
                              "Moringo Organics Pvt Ltd"}
                          </span>
                          <span className="invoice_text_light d-block">
                            {preview?.data?.companyDetails?.address}
                          </span>
                        </div>
                        {preview?.data?.userCountry.toLowerCase() !==
                          "bangladesh" && (
                          <>
                            <div className="invoice-items">
                              <span className="invoice_text_strong">
                                GSTIN:{" "}
                              </span>
                              <span className="invoice_text_light">
                                {
                                  // preview
                                  //     ?.data
                                  //     ?.companyDetails
                                  //     ?.address
                                  preview?.data?.companyDetails?.gstin ??
                                    "33AAHCM8408N1Z3"
                                }
                              </span>
                            </div>
                            <div className="invoice-items">
                              <span>
                                <span className="invoice_text_strong">
                                  State:
                                </span>
                                <span className="invoice_text_light">
                                  {" "}
                                  {preview?.data?.companyDetails?.state ??
                                    "Tamil Nadu,"}
                                </span>
                              </span>
                              <span>
                                <span className="invoice_text_strong">
                                  State Code:{" "}
                                </span>
                                <span className="invoice_text_light">33</span>
                              </span>
                            </div>
                          </>
                        )}
                        <div className="invoice-items">
                          <span>
                            {preview?.data?.userCountry.toLowerCase() !==
                              "bangladesh" ? (
                              <div className="invoice-items">
                                <span>
                                  <span className="invoice_text_strong">
                                    CIN #:
                                  </span>
                                  <span className="invoice_text_light">
                                    {"U51109KA2012PTC063338"}
                                    <br />
                                    {preview?.data?.companyDetails?.email}
                                    {preview?.data?.companyDetails?.phone}
                                  </span>
                                </span>
                              </div>
                            ):(<>
                            <div className="invoice-items">
                                <span>
                                  <span className="invoice_text_light">
                                  
                                    {preview?.data?.companyDetails?.bangladeshEmail ?? "Support@moringo.com.bd"}<br/>
                                    {preview?.data?.companyDetails?.bangladeshWebsite ?? "www.moringoorganics.com"}<br/>
                                    {preview?.data?.companyDetails?.bangladeshNumber ?? "+8801880001278"}
                                  </span>
                                </span>
                              </div>
                            </>)}
                           
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="invoice_border-Box">
                        <div className="invoice-items">
                          <span className="invoice_text_strong">
                            Order Date :{" "}
                          </span>
                          <span className="invoice_text_light">
                            {preview?.data?.date}
                          </span>
                        </div>
                        <div className="invoice-items">
                          <span className="invoice_text_strong">
                            ORDER # :{" "}
                          </span>
                          <span className="invoice_text_light">
                            {preview?.data?.orderData?.invoiceNo}
                          </span>
                        </div>
                        <div className="invoice-items">
                          <span className="invoice_text_strong">IBE # :</span>
                          <span className="invoice_text_light">
                            {preview?.data?.userData?.ibeNo}
                          </span>
                        </div>
                        <div className="invoice-items">
                          <span className="invoice_text_strong">
                            Pay Mode :{" "}
                          </span>
                          <span className="invoice_text_light">
                            {preview?.data?.paymentDetails?.paymentMethod}
                          </span>
                        </div>
                        <div className="invoice-items">
                          <span className="invoice_text_strong">
                            Ship Mode :{" "}
                          </span>
                          <span className="invoice_text_light">
                            {preview?.data?.deliveryMode}
                          </span>
                        </div>
                        <div className="invoice-items">
                          <span className="invoice_text_strong">
                            Order Type :{" "}
                          </span>
                          <span className="invoice_text_light">
                            {preview?.data?.svType}
                          </span>
                        </div>
                        <div className="invoice-items">
                          <span className="invoice_text_strong">
                            Starter Pack Type :{" "}
                          </span>
                          <span className="invoice_text_light">
                            {preview?.data?.userData?.Package?.name}{" "}
                          </span>
                        </div>
                        {preview?.data?.userCountry.toLowerCase() ===
                          "bangladesh" && (
                          <>
                            <div className="invoice-items">
                              <span className="invoice_text_strong">
                                VAT # :{" "}
                              </span>
                              <span className="invoice_text_light">
                                {preview?.data?.companyDetails?.vat ?? "NA"}{" "}
                              </span>
                            </div>
                            <div className="invoice-items">
                              <span className="invoice_text_strong">
                                BIN # :{" "}
                              </span>
                              <span className="invoice_text_light">
                                {preview?.data?.companyDetails?.bin ?? "000827522"}{" "}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div>
                        <h5 className="heading_invoice_box">BILL TO</h5>
                        <div className="invoice_border-Box_with_head">
                          <div className="invoice-items">
                            <span className="invoice_text_strong d-block">
                              {`${preview?.data?.userData?.UserDetail?.name} ${preview?.data?.userData?.UserDetail?.secondName}`}
                            </span>
                            <span className="invoice_text_light d-block">
                              {`${preview?.data?.userData?.UserDetail?.address}, ${preview?.data?.userData?.UserDetail?.address2}`}
                            </span>
                          </div>
                          <div className="invoice-items">
                            <span className="invoice_text_strong">
                              <i
                                className="fa fa-mobile"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span className="invoice_text_light">
                              {`+${preview?.data?.userData?.UserDetail?.phoneCode} ${preview?.data?.userData?.UserDetail?.mobile}`}
                            </span>
                          </div>
                          <div className="invoice-items">
                            <span className="invoice_text_strong">
                              <i
                                className="fa fa-envelope"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span className="invoice_text_light">
                              {preview?.data?.userData?.email}
                            </span>
                          </div>
                          {preview?.data?.userCountry.toLowerCase() !==
                            "bangladesh" && (
                            <>
                              <div className="invoice-items">
                                <span className="invoice_text_strong">
                                  GST :
                                </span>
                                <span className="invoice_text_light">
                                  {preview?.data?.companyDetails?.gst ?? "NA"}
                                </span>
                              </div>
                              <div className="invoice-items">
                                <span className="invoice_text_strong">
                                  State Code :
                                </span>
                                <span className="invoice_text_light">NA</span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div>
                        <h5 className="heading_invoice_box">SHIP TO</h5>
                        <div className="invoice_border-Box_with_head">
                          <div className="invoice-items">
                            <span className="invoice_text_strong d-block">
                              {`${preview?.data?.userData?.UserDetail?.name} ${preview?.data?.userData?.UserDetail?.secondName}`}
                            </span>
                            <span className="invoice_text_light d-block">
                              {`${preview?.data?.userData?.UserDetail?.address}, ${preview?.data?.userData?.UserDetail?.address2}`}
                            </span>
                          </div>
                          <div className="invoice-items">
                            <span className="invoice_text_strong">
                              <i
                                className="fa fa-mobile"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span className="invoice_text_light">
                              {`+${preview?.data?.userData?.UserDetail?.phoneCode} ${preview?.data?.userData?.UserDetail?.mobile}`}
                            </span>
                          </div>
                          <div className="invoice-items">
                            <span className="invoice_text_strong">
                              <i
                                className="fa fa-envelope"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span className="invoice_text_light">
                              {preview?.data?.userData?.email}
                            </span>
                          </div>
                          {preview?.data?.userCountry?.toLowerCase() !==
                            "bangladesh" && (
                            <>
                              <div className="invoice-items">
                                <span className="invoice_text_strong">
                                  GST :
                                </span>
                                <span className="invoice_text_light">
                                  {" "}
                                  {preview?.data?.companyDetails?.gst ?? "NA"}
                                </span>
                              </div>
                              <div className="invoice-items">
                                <span className="invoice_text_strong">
                                  State Code :
                                </span>
                                <span className="invoice_text_light">NA</span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="page-break"></div>
                <div className="invoice_table_responsive_overflow">
                  <div className="modal_invoice_body_min_width">
                    <table className="w-100 table-invoice-calc">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Product Code</th>
                          <th>Product Name</th>
                          <th>HSN/SAC Code</th>
                          <th>QTY</th>
                          <th>Unit Price {conversionFactor?.symbolLeft}</th>
                          <th>Total Amount {conversionFactor?.symbolLeft}</th>
                          <th>Dis</th>
                          <th>Taxable Value {conversionFactor?.symbolLeft}</th>
                          <th colspan="2" className="text-center">
                            {preview?.data?.userCountry.toLowerCase() === "bangladesh"
                              ? "CGST"
                              : "IGST"}
                            <div className="table_head_border_top_colspan">
                              <span>Tax Rate</span>
                              <span className="middle_line"></span>
                              <span>
                                Tax Amount {conversionFactor?.symbolLeft}
                              </span>
                            </div>
                          </th>
                          <th>Total {conversionFactor?.symbolLeft}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {preview?.data?.items?.map((item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item?.productId}</td>
                            <td>{item?.package}</td>

                            <td>{item?.sacCode ?? "NA"}</td>
                            <td>{item?.quantity}</td>
                            <td>
                              {CustomCurrencyConverter(
                                item?.price,
                                conversionFactor?.value
                              )}
                            </td>
                            <td>
                              {CustomCurrencyConverter(
                                item?.amount,
                                conversionFactor?.value
                              )}
                            </td>
                            <td>0.00</td>
                            <td>
                              {CustomCurrencyConverter(
                                item?.amount,
                                conversionFactor?.value
                              )}
                            </td>
                            <td>{item?.gst}</td>
                            <td>
                              {" "}
                              {CustomCurrencyConverter(
                                item?.tax,
                                conversionFactor?.value
                              )}
                            </td>
                            <td>
                              {CustomCurrencyConverter(
                                item?.totalAmount,
                                conversionFactor?.value
                              )}
                            </td>
                          </tr>
                        ))}
                        {/* {preview?.data?.deliveryMode ===
                                                    "Courier" && ( */}
                {/* {
                          <tr>
                            <td>{preview?.data?.items?.length + 1}</td>
                            <td>{"SPI001"} </td>
                            <td
                              // colspan="4"
                              className="text-center"
                            >
                              Shipping Charge
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                              {CustomCurrencyConverter(
                                preview?.data?.totalShippingCharge /
                                  (1 + preview?.data?.gst),
                                conversionFactor?.value
                              )}
                            </td>
                            <td>
                              {CustomCurrencyConverter(
                                preview?.data?.totalShippingCharge /
                                  (1 + preview?.data?.gst),
                                conversionFactor?.value
                              )}
                            </td>
                            <td>0.00</td>
                            <td>
                              {CustomCurrencyConverter(
                                preview?.data?.totalShippingCharge /
                                  (1 + preview?.data?.gst),
                                conversionFactor?.value
                              )}
                            </td>
                            <td>{preview?.data?.gst * 100} </td>
                            <td>
                              {CustomCurrencyConverter(
                                preview?.data?.totalShippingCharge -
                                  preview?.data?.totalShippingCharge /
                                    (1 + preview?.data?.gst),
                                conversionFactor?.value
                              )}
                            </td>

                            <td>
                              {CustomCurrencyConverter(
                                preview?.data?.totalShippingCharge,
                                conversionFactor?.value
                              )}
                            </td>
                          </tr>
                        } */}
                {/* <tr>
                                                    <td
                                                        colspan="5"
                                                        className="text-center"
                                                    >
                                                        Total
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        {
                                                            preview?.data
                                                                ?.subTotal
                                                        }
                                                    </td>
                                                    <td>0.00</td>
                                                    <td>
                                                        {
                                                            preview?.data
                                                                ?.subTotal
                                                        }
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {
                                                            preview?.data
                                                                ?.totalTax
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            preview?.data
                                                                ?.totalAmount
                                                        }
                                                    </td>
                                                </tr> */}
                {/* <tr>
                          <td colspan="4" className="text-center">
                            {preview?.data?.userCountry?.toLowerCase() ===
                            "india"
                              ? "Sub Total"
                              : "Total"}
                          </td>
                          <td>1</td>
                          <td> </td>
                          <td>
                            {CustomCurrencyConverter(
                              Number(preview?.data?.subTotal) +
                                Number(
                                  preview?.data?.totalShippingCharge /
                                    (1 + preview?.data?.gst)
                                ),
                              conversionFactor?.value
                            )}
                          </td>
                          <td>0.00</td>
                          <td>
                            {CustomCurrencyConverter(
                              Number(preview?.data?.subTotal) +
                                Number(
                                  preview?.data?.totalShippingCharge /
                                    (1 + preview?.data?.gst)
                                ),
                              conversionFactor?.value
                            )}
                          </td>
                          <td> </td>
                          <td>
                            {CustomCurrencyConverter(
                              Number(preview?.data?.totalTax) +
                                Number(
                                  preview?.data?.totalShippingCharge -
                                    preview?.data?.totalShippingCharge /
                                      (1 + preview?.data?.gst)
                                ),
                              conversionFactor?.value
                            )}
                          </td>
                          <td>
                            {CustomCurrencyConverter(
                              Number(preview?.data?.totalAmount) +
                                Number(preview?.data?.totalShippingCharge),
                              conversionFactor?.value
                            )}
                          </td>
                        </tr>

                        {preview?.data?.userCountry?.toLowerCase() ===
                          "india" && (
                          <>
                            <tr>
                              <td>{preview?.data?.items?.length + 1}</td>
                              <td>{"PGI001"} </td>
                              <td
                                // colspan="4"
                                className="text-center"
                              >
                                {"Payment Gateway Charges (1.8%)"}
                              </td>
                              <td></td>
                              <td></td>
                              <td>
                                {CustomCurrencyConverter(
                                  Number(preview?.data?.paymentGatewayFee) /
                                    (1 +
                                      Number(
                                        preview?.data?.PaymentGatewayGSTPerc
                                      )),
                                  conversionFactor?.value
                                )}
                              </td>
                              <td>
                                {CustomCurrencyConverter(
                                  Number(preview?.data?.paymentGatewayFee) /
                                    (1 +
                                      Number(
                                        preview?.data?.PaymentGatewayGSTPerc
                                      )),
                                  conversionFactor?.value
                                )}
                              </td>
                              <td>0.00</td>
                              <td>
                                {CustomCurrencyConverter(
                                  Number(preview?.data?.paymentGatewayFee) /
                                    (1 +
                                      Number(
                                        preview?.data?.PaymentGatewayGSTPerc
                                      )),
                                  conversionFactor?.value
                                )}
                              </td>
                              <td>
                                {preview?.data?.PaymentGatewayGSTPerc * 100}{" "}
                              </td>
                              <td>
                                {CustomCurrencyConverter(
                                  (Number(preview?.data?.paymentGatewayFee) /
                                    (1 +
                                      Number(
                                        preview?.data?.PaymentGatewayGSTPerc
                                      ))) *
                                    Number(
                                      preview?.data?.PaymentGatewayGSTPerc
                                    ),
                                  conversionFactor?.value
                                )}
                              </td>

                              <td>
                                {CustomCurrencyConverter(
                                  Number(preview?.data?.paymentGatewayFee),
                                  conversionFactor?.value
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4" className="text-center">
                                {"Total"}
                              </td>
                              <td>1</td>
                              <td> </td>
                              <td>
                                {CustomCurrencyConverter(
                                  Number(preview?.data?.subTotal) +
                                    Number(
                                      preview?.data?.totalShippingCharge /
                                        (1 + preview?.data?.gst)
                                    ) +
                                    Number(preview?.data?.paymentGatewayFee) /
                                      (1 +
                                        Number(
                                          preview?.data?.PaymentGatewayGSTPerc
                                        )),
                                  conversionFactor?.value
                                )}
                              </td>
                              <td>0.00</td>
                              <td>
                                {CustomCurrencyConverter(
                                  Number(preview?.data?.subTotal) +
                                    Number(
                                      preview?.data?.totalShippingCharge /
                                        (1 + preview?.data?.gst)
                                    ) +
                                    Number(preview?.data?.paymentGatewayFee) /
                                      (1 +
                                        Number(
                                          preview?.data?.PaymentGatewayGSTPerc
                                        )),
                                  conversionFactor?.value
                                )}
                              </td>
                              <td> </td>
                              <td>
                                {CustomCurrencyConverter(
                                  Number(preview?.data?.totalTax) +
                                    Number(
                                      preview?.data?.totalShippingCharge -
                                        preview?.data?.totalShippingCharge /
                                          (1 + preview?.data?.gst)
                                    ) +
                                    (Number(preview?.data?.paymentGatewayFee) /
                                      (1 +
                                        Number(
                                          preview?.data?.PaymentGatewayGSTPerc
                                        ))) *
                                      Number(
                                        preview?.data?.PaymentGatewayGSTPerc
                                      ),
                                  conversionFactor?.value
                                )}
                              </td>
                              <td>
                                {CustomCurrencyConverter(
                                  Number(preview?.data?.totalAmount) +
                                    Number(preview?.data?.totalShippingCharge) +
                                    Number(preview?.data?.paymentGatewayFee),
                                  conversionFactor?.value
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="">
                  <div className="mt-3">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-6"></div>
                        <div className="col-lg-6">
                          <div className="total_amout_table">
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    {`Total Amount before Tax (${conversionFactor?.symbolLeft})`}
                                  </td>
                                  <td>
                                    {CustomCurrencyConverter(
                                      Number(preview?.data?.subTotal) +
                                        Number(
                                          preview?.data?.totalShippingCharge /
                                            (1 + preview?.data?.gst)
                                        ) +
                                        Number(
                                          preview?.data?.paymentGatewayFee
                                        ) /
                                          (1 +
                                            Number(
                                              preview?.data
                                                ?.PaymentGatewayGSTPerc
                                            )),
                                      conversionFactor?.value
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {preview?.data?.userCountry?.toLowerCase() ===
                                    "india"
                                      ? `Add: IGST (${conversionFactor?.symbolLeft})`
                                      : "Vat 5%"}
                                  </td>
                                  <td>
                                    {CustomCurrencyConverter(
                                      Number(preview?.data?.totalTax) +
                                        Number(
                                          preview?.data?.totalShippingCharge -
                                            preview?.data?.totalShippingCharge /
                                              (1 + preview?.data?.gst)
                                        ) +
                                        (Number(
                                          preview?.data?.paymentGatewayFee
                                        ) /
                                          (1 +
                                            Number(
                                              preview?.data
                                                ?.PaymentGatewayGSTPerc
                                            ))) *
                                          Number(
                                            preview?.data?.PaymentGatewayGSTPerc
                                          ),
                                      conversionFactor?.value
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Discount</td>
                                  <td>00.00</td>
                                </tr>
                                <tr>
                                  <td>
                                    {"Total Amount after Tax (Round Up value)"}
                                  </td>
                                  <td>
                                    {conversionFactor?.symbolLeft}
                                    {CustomCurrencyConverter(
                                      Number(preview?.data?.totalAmount) +
                                        Number(
                                          preview?.data?.totalShippingCharge
                                        ) +
                                        Number(
                                          preview?.data?.paymentGatewayFee
                                        ),
                                      conversionFactor?.value
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <p className="disclaimer">
                      Ceritified that the particulars given above are true and
                      correct. This is a system generated Invoice, doesn't
                      require signature &amp; stamp.
                    </p>
                  </div>
                  <div className="mt-3">
                    <h6 className="invoice_text_strong">Important Note:</h6>
                    <p className="disclaimer mt-0">
                      For self-pickup orders, the Independent Brand Executive
                      must pick up the products from the stock point within 15
                      days of the order date. If not collected within 15 days,
                      the Independent Brand Executive cannot pick up the
                      products from the stock point. In such cases, you can
                      obtain the product directly from the company by paying the
                      courier charges for the order within 30 days.
                    </p>
                  </div>
                  <div className="mt-4">
                    <h4 className="tankyou_text text-center">
                      Thank you for your business.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
                {/* </div>
        {preview?.data?.pendingStatus && (
          <div className="col-md-8 m-auto">
            <div className="purchase_invoice" ref={tableRef}>
              <div
                className="alertNotification"
                style={{
                  width: "100%",
                  border: "1px solid rgb(153 88 53)",
                  background: "rgba(153, 88, 53, 0.4)",
                  display: "inline-block",
                  color: "rgb(153 88 53)",
                  padding: "8px 15px 8px 15px",
                  // borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <p style={{ margin: "0" }}>
                  {t("regCompleted")}{" "}
                  {user?.status === "pending" &&
                    " but is still awaiting final confirmation."}
                </p>
              </div>
              <h3 style={{ margin: "20px" }}>Registration Details</h3>
              <table className="striped">
                <thead
                  style={{
                    backgroundColor: "rgb(153 88 53)",
                    color: "white",
                  }}
                >
                  {" "}
                  <tr>
                    <th
                      className="text-uppercase  font-weight-bold"
                      style={{ textAlign: "center" }}
                    ></th>{" "}
                    <th className="text-uppercase  font-weight-bold"></th>{" "}
                  </tr>
                </thead>
                <tbody>
                  <tr className="font-weight-bold mb-1">
                    <td>{t("Invoice No")}</td>
                    <td>{order?.invoiceNo}</td>
                  </tr>
                  <tr className="font-weight-bold mb-1">
                    <td>{t("username")}</td>
                    <td>{user?.username}</td>
                  </tr>
                  <tr className="font-weight-bold mb-1">
                    <td>{t("fullName")}</td>
                    <td>{user?.fullName}</td>
                  </tr>
                  <tr className="font-weight-bold mb-1">
                    <td>{t("sponsor")}</td>
                    <td>{user?.sponsorName}</td>
                  </tr>
                  <tr className="font-weight-bold mb-1">
                    <td>{t("entry_level")}</td>
                    <td>{user?.package}</td>
                  </tr>
                  <tr className="font-weight-bold mb-1">
                    <td>{t("totalAmount")}</td>
                    <td>
                      {`${
                        conversionFactor?.symbolLeft
                      } ${CustomCurrencyConverter(
                        order?.totalAmount,
                        conversionFactor?.value
                      )}`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )} */}
            </div>
        </div>
    );
};

export default RegisterCompleteNew;
