import { grey } from "@mui/material/colors";
import { tr } from "date-fns/locale";
import { event } from "jquery";
import React from "react";
// import TablePagination from "./table/TablePagination";

const CommonTableComponent = ({
  headers,
  datas,
  filters,
  setFilters,
  searchOption = false,
  onSearch = () => {},
  firstElementSticky = false,
  // startPage = 1,
  // currentPage = 1,
  // totalPages = 1,
  // setCurrentPage,
  // itemsPerPage = 10,
  // toNextPage,
  // toLastPage,
  // toPreviousPage,
  // toFirstPage,
  isLoading = false,
}) => {
  const defaultRender = (el) => <span>{el}</span>;
  return (
    <div className="common-table-container">
      <table className="common-table" style={{ width: "100%" }}>
        <thead>
          {/* <div className="table-header-bg"></div> */}
          <tr
            className={`table-header-bg table-header ${
              firstElementSticky ? "first-element-sticky" : ""
            } `}
          >
            {headers &&
              headers?.length &&
              headers.map((item, index) => (
                <th key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.label,
                    }}
                    className={{}}
                  ></div>
                  {/* {item.label} */}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {searchOption && (
            <tr
              className={`table-data search-sticky ${
                firstElementSticky ? "first-element-sticky" : ""
              } `}
            >
              {headers &&
                headers?.length &&
                headers.map((item, index) => (
                  <td key={index}>
                    {item.filterable &&
                      !filters?.[item?.filter]?.isEditting &&
                      !filters?.[item?.filter]?.value && (
                        <i
                          className="fa-solid fa-magnifying-glass"
                          style={{
                            color: "#c4c4c4",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            let prev = filters?.[item?.filter];
                            setFilters((state) => ({
                              ...state,
                              [item?.filter]: {
                                ...prev,
                                isEditting: true,
                              },
                            }));
                          }}
                        ></i>
                      )}
                    {item.filterable &&
                      ((filters?.[item?.filter]?.value !== "" &&
                        filters?.[item?.filter]?.value) ||
                        filters?.[item?.filter]?.isEditting) && (
                        <div
                          className="table-data-search"
                          onBlur={() => {
                            if (
                              filters?.[item?.filter]?.isEditting &&
                              filters?.[item?.filter]?.value === ""
                            ) {
                              let prev = filters?.[item?.filter];
                              setFilters((state) => ({
                                ...state,
                                [item?.filter]: {
                                  ...prev,
                                  isEditting: false,
                                  value: "",
                                },
                              }));
                              onSearch();
                            }
                          }}
                        >
                          <input
                            type={item?.type === "date" ? "date" : "text"}
                            value={filters?.[item?.filter]?.value}
                            onChange={(event) => {
                              let prev = filters?.[item?.filter];
                              setFilters((state) => ({
                                ...state,
                                [item?.filter]: {
                                  ...prev,
                                  value: event.target.value,
                                  isEditting: true,
                                },
                              }));
                            }}
                          ></input>
                          {filters?.[item?.filter]?.isEditting && (
                            <button
                              onClick={() => {
                                let prev = filters?.[item?.filter];
                                setFilters((state) => ({
                                  ...state,
                                  [item?.filter]: {
                                    ...prev,
                                    isEditting: false,
                                  },
                                }));
                                onSearch();
                              }}
                            >
                              <i
                                className="fa-solid fa-magnifying-glass"
                                style={{
                                  color: "#c4c4c4",
                                  fontSize: "20px",
                                }}
                              ></i>
                            </button>
                          )}
                          {!filters?.[item?.filter]?.isEditting && (
                            <button
                              onClick={() => {
                                let prev = filters?.[item?.filter];
                                setFilters((state) => ({
                                  ...state,
                                  [item?.filter]: {
                                    ...prev,
                                    value: "",
                                  },
                                }));
                                onSearch();
                              }}
                              style={{
                                backgroundColor: "#e0c0c0",
                                color: "rgb(158 2 2)",
                                fontSize: "16px",
                              }}
                            >
                              <i className="fa-solid fa-x"></i>
                            </button>
                          )}
                        </div>
                      )}
                  </td>
                ))}
            </tr>
          )}
          {isLoading && (
            // <tr>
            //     <td colSpan={headers?.length} style={{ backgroundColor: "#fff" }}>
            //     <div className="loader-table-view">
            //         <div className="loader-table-view-box">
            //         <div className="loader-spinner"></div>
            //         <div className="loader-table-view-box-txt">{"Loading..."}</div>
            //         </div>
            //     </div>
            //     </td>
            // </tr>
            <tr>
              <td className="loader-overlay-sticky ">
                <div className="loader-overlay">
                  <div className="loader-table-view">
                    <div className="loader-table-view-box">
                      <div className="loader-spinner"></div>
                      <div className="loader-table-view-box-txt">
                        {"Loading..."}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )}

          {!isLoading &&
            datas &&
            datas.length != 0 &&
            datas?.map((raw, index) => {
              return (
                <tr
                  key={index}
                  className={`table-data ${
                    firstElementSticky ? "first-element-sticky" : ""
                  } `}
                >
                  {headers &&
                    headers?.length &&
                    headers.map((item, index) => (
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          // dangerouslySetInnerHTML={{
                          //     // __html: raw[
                          //     //     item.filter
                          //     // ],
                          //     __html: item.render("item.label")
                          // }}
                          // className={{}}
                        >
                          {item?.render
                            ? item?.render(raw[item.filter])
                            : defaultRender(raw[item.filter])}
                        </div>
                      </td>
                    ))}
                </tr>
              );
            })}
          {!isLoading && datas?.length === 0 && (
            <>
              <tr>
                <td
                  colSpan={headers?.length}
                  style={{ backgroundColor: "#fff" }}
                >
                  <div className="nodata-table-view">
                    <div className="nodata-table-view-box">
                      <div className="nodata-table-view-box-img">
                        <img src="/images/no-data-image1.jpg" alt="" />
                      </div>
                      <div className="nodata-table-view-box-txt">
                        {"Sorry No Data Found"}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CommonTableComponent;
