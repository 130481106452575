import { format } from "date-fns";

export const formatDate = (dateString) => {
    if (!dateString) {
        return ""; // Handle empty or null values gracefully.
    }
    const formattedDate = format(new Date(dateString), "dd MMM yyyy HH:mm:ss");
    return formattedDate;
};

export const formatDateWithoutTime = (dateString) => {
    if (!dateString) {
        return ""; // Handle empty or null values gracefully.
    }
    const formattedDate = format(new Date(dateString), "dd MMM yyyy");
    return formattedDate;
};

export const ticketFormatDate = (dateString) => {
    if (!dateString) {
        return ""; // Handle empty or null values gracefully.
    }
    const formattedDate = format(new Date(dateString), "dd/MMM/yyyy  HH:mm");
    return formattedDate;
};

export const crmFormateDate = (dateString) => {
    if (!dateString) {
        return ""; // Handle empty or null values gracefully.
    }
    const formattedDate = format(new Date(dateString), "MM-dd-yyyy");
    return formattedDate;
};

export const formatDateWithoutTimeStd = (dateString) => {
    if (!dateString) {
        return ""; // Handle empty or null values gracefully.
    }
    const formattedDate = format(new Date(dateString), "dd/mm/yyyy");
    return formattedDate;
};
export const formatDateForInput = (date) => {
    if (!date) return "";
  
    let parsedDate;
  
    try {
      if (date instanceof Date) {
        parsedDate = date;
      }
      else if (typeof date === 'string') {
        if (date.includes('/')) {
          const [day, month, year] = date.split('/');
          parsedDate = new Date(year, month - 1, day);
        } else {
          parsedDate = new Date(date);
        }
      }
      
      const offset = parsedDate.getTimezoneOffset();
      const adjustedDate = new Date(parsedDate.getTime() - (offset * 60 * 1000));
      return adjustedDate.toISOString().split('T')[0];
    } catch (error) {
      console.error('Error formatting date:', error);
      return "";
    }
  };
  