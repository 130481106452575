import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import { exportToExcelNew } from "../../utils/tableExports";
const IbeCustomerOrderReport = () => {
    const { t } = useTranslation();
    const headers = [
        // { label: "SELLER IBE #", filter: "seller_ibe", filterable: true },
        // {
        //     label: "SELLER IBE NAME",
        //     filter: "seller_ibe_name",
        //     filterable: true,
        // },
        { label: "CS #", filter: "cs", filterable: true },
        { label: "CS NAME", filter: "cs_name", filterable: true },
        { label: "STATE", filter: "state", filterable: true },
        { label: "COUNTRY", filter: "country", filterable: true },
        { label: "ORD #", filter: "ord", filterable: true },
        { label: "ORD DATE", filter: "ord_date", filterable: true },
        { label: "ORD AMT $", filter: "ord_amt_usd", filterable: true },
        { label: "ORD AMT ₹", filter: "ord_amt_inr", filterable: true },
        { label: "PAY MODE", filter: "pay_mode", filterable: true },
        { label: "DELIVERY MODE", filter: "delivery_mode", filterable: true },
        // { label: "TXN ID", filter: "txn_id", filterable: true },
        {
            label: "INVOICE",
            filter: "invoice",
            filterable: false,
            render: (el) => (
                <a href={el} target="_blank">
                    <i class="fa-solid fa-download"></i>
                </a>
            ),
        },
        { label: "SV", filter: "sv", filterable: true },
    ];

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [apiCallStatus1, setApiCallStatus1] = useState(false);
    const [filters, setFilters] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const ibeOrderReport = ApiHook.CallgetIbeCustomerOrderReport({
        filters: Object.entries(filters)?.reduce((prev, curr) => {
            if (!curr[1]?.isEditting && curr[1]?.value !== "") {
                return { ...prev, [curr[0]]: curr[1]?.value };
            }
            return prev;
        }, {}),
        apiCallStatus,
        setApiCallStatus,
        setCurrentPage,
        setTotalPages,
        currentPage,
    });


    const exportFunc = (data) => {
            exportToExcelNew(data, headers, "ibe-customer-order-report");
        };
        const excelData = ApiHook.CallgetIbeCustomerOrderReport({
            filters: {},
            apiCallStatus: apiCallStatus1,
            setApiCallStatus: setApiCallStatus1,
            setCurrentPage: () => {},
            setTotalPages: () => {},
            currentPage: 0,
            isAll: 1,
            exportFunc,
        });
        const startPage = 1;
        const itemsPerPage = 10;
        const toNextPage = () => {
            // setIsFetchable(true);
            if (currentPage < totalPages) {
                setCurrentPage(currentPage + 1);
                setApiCallStatus(true);
            }
        };
    
        const toLastPage = () => {
            setCurrentPage(totalPages);
            setApiCallStatus(true);
        };
    
        const toPreviousPage = () => {
            if (currentPage > startPage) {
                setCurrentPage(currentPage - 1);
                setApiCallStatus(true);
            }
        };
    
        const toFirstPage = () => {
            setCurrentPage(startPage);
            setApiCallStatus(true);
        };
    
        const gotopage = (page) => {
            setCurrentPage(page);
            setApiCallStatus(true);
        }

    
        const slider = document.getElementsByClassName("report-table-container")[0];
        if (slider) {
            let isDown = false;
            let startX;
            let scrollLeft;
    
            slider.addEventListener("mousedown", (e) => {
                isDown = true;
                slider.classList.add("active");
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener("mouseleave", () => {
                isDown = false;
                slider.classList.remove("active");
            });
            slider.addEventListener("mouseup", () => {
                isDown = false;
                slider.classList.remove("active");
            });
            slider.addEventListener("mousemove", (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        }
    return (
        <>
            <div className="report-Container">
                <div className="report-header">IBE CUSTOMER ORDER REPORT</div>
                <div className="report-table-container-flex">
                    <div
                        className="report-table-container scrollbar-blue"
                        // style={{ display: "flex", flexDirection: "column" }}
                    >
                        <CommonTableComponent
                            headers={headers}
                            datas={ibeOrderReport?.data?.data}
                            filters={filters}
                            firstElementSticky={false}
                            searchOption={true}
                            setFilters={setFilters}
                            onSearch={() => {
                                setApiCallStatus(true);
                            }}
                            isLoading={ibeOrderReport?.isLoading}
                        />
                    </div>
                </div>
                <div className="report-table-container-flex">
                    <div className="pagination-container">
                        {ibeOrderReport?.data?.data &&!ibeOrderReport?.isLoading&&
                            ibeOrderReport?.data?.data?.length !== 0 && (
                                <TablePagination
                                startPage={startPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={itemsPerPage}
                                toNextPage={toNextPage}
                                toLastPage={toLastPage}
                                toPreviousPage={toPreviousPage}
                                toFirstPage={toFirstPage}
                                exportButton={true}
                                exportFunc={() => {
                                    setApiCallStatus1(true);
                                }}
                                gotopage={gotopage}
                                />
                            )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeCustomerOrderReport;
