import React, { useState } from "react";
import "./dash.css";
import {
  BarChart,
  BarCommissionChart,
  DoughnutChart,
  LineChart,
} from "../components/Common/charts/charts";
import DashboardService from "../services/dashboard/Dashboard";
import { ApiHook } from "../hooks/apiHook";

const DashLayout = () => {
  const appLayout = ApiHook.CallAppLayout();

  const [dashboardCheck, setDashboardCheck] = useState(true);

  const rightSection = ApiHook.CallDashboardRight(
    dashboardCheck,
    setDashboardCheck
  );
  const { data: RankChartData } = DashboardService.GetRankChart();

  const rankLabels = RankChartData?.rankLabel?.map((item) => `Week ${item}`);

  let ltsvArray = rightSection?.data?.teamCommissionData?.ltsv || ["LTSV"];
  let rtsvArray = rightSection?.data?.teamCommissionData?.rtsv || ["RTSV"];

  let currentWeekLtsv = ltsvArray.at(-1);
  let currentWeekRtsv = rtsvArray.at(-1);

  console.log({ currentWeekLtsv, currentWeekRtsv  })
  
  return (
    <div>
      {/* Tiles */}
      <div className="dash-tile">
        <div className="d-flex flex-column text-center  justify-content-center ">
          <div>
            <div className="dash_few_box_name">Join Date</div>
            <div className="member-text">
              {" "}
              {rightSection?.data?.userProfile?.dateOfJoin}
            </div>
          </div>
          <div className="mt-3">
            <div className="dash_few_box_name">Last Order Date</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.lastOrderDate}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column text-center  justify-content-center">
          <div>
            <div className="dash_few_box_name">Live Rank</div>
            <div className="member-text">
              {" "}
              {rightSection?.data?.userProfile?.rankName ?? "NA"}
            </div>
          </div>
          <div className="mt-3">
            <div className="dash_few_box_name">Status</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.status}{" "}
              {rightSection?.data?.userProfile?.status === "Active" && (
                <i
                  className="fa-solid fa-circle-check"
                  style={{ color: "#749842" }}
                ></i>
              )}
              {rightSection?.data?.userProfile?.status === "Inactive" && (
                <i
                  className="fa-solid fa-circle-xmark"
                  style={{ color: "#c91a1a" }}
                ></i>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column text-center align-items-center  justify-content-center">
          <div>
            <div className="dash_few_box_name">Highest Rank</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.highestRank ?? "NA"}
            </div>
          </div>
          <div>
            <div className="dash_few_box_name">Last Week Rank</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.lastWeekRank ?? "NA"}
            </div>
          </div>
        </div>
        {/* </div>
            <div className="dash-tile"> */}
        <div
          className="d-flex gap-3 text-center align-items-center  justify-content-center"
          style={{ flexDirection: "column" }}
        >
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="dash_few_box_name">PSV</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.psv ?? 0}
                  </div>
                </td>
                <td>
                  <div className="dash_few_box_name">PCV</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.pcv ?? 0}
                  </div>
                </td>
                <td>
                  <div className="dash_few_box_name">QSV</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.qsv ?? 0}
                  </div>
                </td>
              </tr>
             
              <tr>
              <td>
                  <div className="dash_few_box_name">RSV</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.rsv ?? 0}
                  </div>
                </td>
                <td>
                  <div className="dash_few_box_name">PTC</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.tc ?? 0}
                  </div>
                </td>
                <td>
                  <div className="dash_few_box_name">PTS</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.ts ?? 0}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex flex-column text-center align-items-center  justify-content-center">
          <div>
            {/* <div>Week #</div> */}
            <div className="member-text">
              {rightSection?.data?.userProfile?.week ?? "Week 0"}
            </div>
          </div>
          <div>
            {/* <div>Week #</div> */}
            <div className="member-text">
              {rightSection?.data?.userProfile?.weekPeriod}
            </div>
          </div>
          <div className="mt-3">
            <div className="dash_few_box_name">Level</div>
            <div className="member-text">
              {rightSection?.data?.userProfile?.packageName}
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 text-center align-items-center  justify-content-center"
          style={{ flexDirection: "column" }}
        >
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="dash_few_box_name">LC</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.leftCount ?? 0}
                  </div>
                </td>
                
                <td>
            
                  <div className="dash_few_box_name">CFVL</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.cfvl ?? 0}
                  </div>
                </td>
                <td>
                  <div className="dash_few_box_name">QCFVL</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.qcfvl ?? 0}
                  </div>
                </td>
              </tr>
             
              <tr>
              <td>
                  <div className="dash_few_box_name">RC</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.rightCount ?? 0}
                  </div>
                </td>
              <td>
                  
                  <div className="dash_few_box_name">CFVR</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.cfvr ?? 0}
                  </div>
                </td>
                <td>
                  <div className="dash_few_box_name">QCFVR</div>
                  <div className="member-text">
                    {rightSection?.data?.userProfile?.qcfvr ?? 0}
                  </div>
                </td>
               
              </tr>
            </tbody>
          </table>
          {/* <div className="d-flex gap-4">
            <div>
              <div className="dash_few_box_name">Left Count</div>
              <div className="member-text">
                {rightSection?.data?.userProfile?.leftCount ?? 0}
              </div>
            </div>
            <div>
              <div className="dash_few_box_name">CFVL</div>
              <div className="member-text">
                {rightSection?.data?.userProfile?.cfvl ?? 0}
              </div>
            </div>
            <div>
              <div className="dash_few_box_name">QCFVL</div>
              <div className="member-text">
                {rightSection?.data?.userProfile?.qcfvl ?? 0}
              </div>
            </div>
          </div>
          <div className="d-flex gap-4">
            <div>
              <div className="dash_few_box_name">Right Count</div>
              <div className="member-text">
                {rightSection?.data?.userProfile?.rightCount ?? 0}
              </div>
            </div>
            <div>
              <div className="dash_few_box_name">CFVR</div>
              <div className="member-text">
                {rightSection?.data?.userProfile?.cfvr ?? 0}
              </div>
            </div>
            <div>
              <div className="dash_few_box_name">QCFVR</div>
              <div className="member-text">
                {rightSection?.data?.userProfile?.qcfvr ?? 0}
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* Charts */}
      <div className="row first_chart_pb_sm">
        <div className="mt-4 col-xl-6 col-md-12 col-sm-12">
          <div className="dash-charts">
            <div className="d-md-flex d-block text-md-start text-center gap-1 align-items-center justify-content-between mb-4">
              <h4 className="mb-0 chart-head">{currentWeekLtsv}/{currentWeekRtsv}</h4>
              <div className="d-flex gap-2 align-items-center my-md-0 my-3 justify-content-center justify-content-md-start">
                <div className="d-flex align-items-center gap-1">
                  <div className="ltsv-label"></div>{" "}
                  <div className="chart-label_contry">LTSV</div>
                </div>
                <div className="d-flex align-items-center gap-1">
                  <div className="rtsv-label"></div>{" "}
                  <div className="chart-label_contry">RTSV</div>
                </div>
              </div>
              <div className="d-flex justify-content-center justify-content-md-start">
                <div className="tsv-data-filter button_week_change text-md-start ">
                  Weekly
                </div>
              </div>
            </div>
            {rightSection?.data && (
              <BarChart
                labels={rightSection?.data?.labels}
                datas={rightSection?.data?.teamCommissionData}
              />
            )}
          </div>
        </div>
        <div className="mt-4 col-xl-6 col-md-12 col-sm-12">
          <div className="dash-charts">
            <div className="d-md-flex d-block text-md-start text-center gap-1  align-items-md-start align-items-center justify-content-between mb-4">
              <h4 className="mb-0 chart-head">Rank</h4>
              <div className="d-flex justify-content-center justify-content-md-start mt-md-0 mt-3">
                <div className="tsv-data-filter button_week_change text-md-start">
                  Weekly
                </div>
              </div>
            </div>

            <LineChart data={RankChartData?.rankValue} labels={rankLabels} />
          </div>
        </div>
      </div>
      <div className="row last_chart_pb_sm">
        <div className="mt-4 col-xl-6 col-md-12 col-sm-12">
          <div
            className="dash-charts h-100"
            style={{ display: "grid", alignItems: "start" }}
          >
            <h4 className="mb-4 chart-head">Total Team Count</h4>
            <div className="row">
              <div className="col-xxl-7 col-xl-7 col-md-6">
                <div
                  className="d-flex justify-content-center"
                  style={{ maxWidth: "100%" }}
                >
                  {rightSection?.data && (
                    <DoughnutChart
                      labels={["Active", "Inactive", "Expired", "Terminated"]}
                      data={rightSection?.data?.doughnutData}
                    />
                  )}
                </div>
              </div>
              <div className="col-xxl-5 col-xl-5 col-md-6 d-flex align-items-center justify-content-md-start justify-content-center">
                <div className="d-sm-block d-flex justify-content-center ">
                  <div className="dog-nut_container-sm">
                    <div
                      className="row justify-content-between mt-4 mt-md-0 doghnut-label-p"
                      style={{ rowGap: "12px" }}
                    >
                      <div className="col-xxl-12 col-auto p-0">
                        <div className="d-flex gap-2 align-items-center">
                          <div
                            className="doghnut-label-dot label-1"
                          ></div>
                          <div className="dognut-label">Active</div>
                          <div>
                            {rightSection?.data?.teamUserStatuscount?.active}%
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-12 col-auto p-0">
                        <div className="d-flex gap-2 align-items-center">
                          <div
                            className="doghnut-label-dot label-2"
                        
                          ></div>
                          <div className="dognut-label">Inactive</div>
                          <div>
                            {rightSection?.data?.teamUserStatuscount?.inactive}%
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-12 col-auto p-0">
                        <div className="d-flex gap-2 align-items-center">
                          <div
                            className="doghnut-label-dot label-3"
     
                          ></div>
                          <div className="dognut-label">Expired</div>
                          <div>
                            {rightSection?.data?.teamUserStatuscount?.expired}%
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-12 col-auto p-0">
                        <div className="d-flex gap-2 dognut-label-gap align-items-center">
                          <div
                            className="doghnut-label-dot label-4"
                     
                          ></div>
                          <div className="dognut-label">Terminated</div>
                          <div>
                            {
                              rightSection?.data?.teamUserStatuscount
                                ?.terminated
                            }
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex flex-column gap-4 w-100 ps-5">
                                <div className="d-flex gap-2 align-items-center">
                                    <div
                                        className="doghnut-label-dot"
                                        style={{ background: "#904B21" }}
                                    ></div>
                                    <div>Active</div>
                                    <div style={{ fontSize: "1.2em" }}>60%</div>
                                </div>
                                <div className="d-flex gap-2 align-items-center">
                                    <div
                                        className="doghnut-label-dot"
                                        style={{ background: "#C8CFB7" }}
                                    ></div>
                                    <div>Inactive</div>
                                    <div style={{ fontSize: "1.2em" }}>
                                        12.5%
                                    </div>
                                </div>
                                <div className="d-flex gap-2 align-items-center">
                                    <div
                                        className="doghnut-label-dot"
                                        style={{ background: "#749842" }}
                                    ></div>
                                    <div>Expired</div>
                                    <div style={{ fontSize: "1.2em" }}>
                                        17.5%
                                    </div>
                                </div>
                                <div className="d-flex gap-2 align-items-center">
                                    <div
                                        className="doghnut-label-dot"
                                        style={{ background: "#2E8CA5" }}
                                    ></div>
                                    <div>Terminated</div>
                                    <div style={{ fontSize: "1.2em" }}>10%</div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
        <div className="mt-4 col-xl-6 col-md-12 col-sm-12">
          <div className="dash-charts h-100 d-grid align-content-between">
            <div className="d-md-flex d-block text-md-start text-center gap-1 align-items-md-start align-items-center justify-content-between mb-4">
              <h4 className="mb-0 chart-head">Commission</h4>
              <div className="d-flex justify-content-center justify-content-md-start mt-md-0 mt-3">
                <div className="tsv-data-filter">Weekly</div>
              </div>
            </div>
            <BarCommissionChart
              labels={rightSection?.data?.labels}
              datas={rightSection?.data?.commissionData}
            />
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default DashLayout;
