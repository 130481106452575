import moment from "moment";
import React from "react";
import { formatDateForInput } from "../../utils/formateDate";

const CommonInput = ({
    type = "text",
    label = "text",
    disabled = false,
    defaultValue = "",
    placeholder,
    onChange = null,
    value,
    backgroundColor = "#fff",
    options = [],
    phoneCode = "",
    phoneCodeChange = () => {},
    phoneCodeDisabled = false,
    formRegister = {},
    onBlur = () => {},
    max = moment().format("YYYY-MM-DD"),
    isLabelDisabled = false,
}) => {
    const registerOptions = formRegister;
    if (disabled) {
        onChange = () => {};
    }
    return (
        <div
            className="common-input-container"
            style={{ backgroundColor: backgroundColor }}
        >
            {!isLabelDisabled && (
                <h6 className="common-input-label"> {label.toUpperCase()}</h6>
            )}
            <div
                className="common-input-div"
                style={{ "--pseudo-element-color": backgroundColor }}
            >
                {type === "mobile" && (
                    <div className="common-mobile-container">
                        <div className="custom-select-wrapper">
                            <select
                                className="custom-mb-select"
                                onChange={phoneCodeChange}
                                value={phoneCode}
                                defaultValue={""}
                                disabled={phoneCodeDisabled}
                            >
                                <option
                                    value=""
                                    disabled
                                    style={{ color: "lightgray" }}
                                >
                                    {""}
                                </option>
                                {options.map((option, index) => (
                                    <option key={index} value={option}>
                                        {`+${option}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <input
                            type="text"
                            placeholder={placeholder ?? label}
                            className="custom-mb-input"
                            {...registerOptions}
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                            onBlur={onBlur}
                        />
                    </div>
                )}
                {type === "select" && (
                    <select
                        className="form-new-select"
                        disabled={disabled}
                        value={value}
                        defaultValue={defaultValue}
                        {...registerOptions}
                        onChange={onChange}
                    >
                        <option
                            value=""
                            disabled ={true}
                            style={{ color: "lightgray" }}
                        >
                            {placeholder ?? label}
                        </option>
                        {options.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                )}
                {type === "date" && (
                    <input
                        type={type}
                        className="form-new-input"
                        placeholder={placeholder ?? label}
                        {...registerOptions}
                        onChange={onChange ?? registerOptions?.onChange ?? null}
                        value={formatDateForInput(value)}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        style={{
                            "--pseudo-element-color": backgroundColor,
                        }}
                        onBlur={onBlur}
                        max={max}
                    />
                )}
                {(type === "text" ||
                    type === "number" ||
                    type === "password" ||
                    type === "email") && (
                    <input
                        type={type}
                        className="form-new-input"
                        placeholder={placeholder ?? label}
                        {...registerOptions}
                        onChange={onChange ?? registerOptions?.onChange ?? null}
                        value={value}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        style={{
                            "--pseudo-element-color": backgroundColor,
                        }}
                        onBlur={onBlur}
                    />
                )}
               
            </div>
        </div>
    );
};

export default CommonInput;
