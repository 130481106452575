import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { useState } from "react";
import EventList from "./EventList";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import moment from "moment";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";
import TablePagination from "../../components/Common/table/TablePagination";
import { Image } from "antd";

const EventPurchase = () => {
  const { t } = useTranslation();
  const [totalAmount, setTotalAmount] = useState(0);
  const [conversionFactor, setConversionFactor] = useState();

  const START_PAGE = 1;

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  const toNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const toLastPage = () => {
    setCurrentPage(totalPages);
  };
  const toPreviousPage = () => {
    if (currentPage > START_PAGE) {
      setCurrentPage(currentPage - 1);
    }
  };
  const toFirstPage = () => {
    setCurrentPage(START_PAGE);
  };

  const eventList = ApiHook.CallGetEvents();
  const [events, setEvent] = useState([]);
  const eventReport = ApiHook.CallEventReport(
    setTotalPages,
    currentPage,
    itemsPerPage
  );
  console.log(eventReport?.isLoading);

  const statusColorLookUp = {
    Approved: { backgroundColor: "#c7e6d3", color: "#66bc7e" },
    Pending: { backgroundColor: "#E4F0F2", color: "#2E8CA5" },
  };
  useEffect(() => {
    setConversionFactor(
      eventList?.userCountry.toLowerCase() === "india"
        ? {
            symbolLeft: eventList?.activeCurrencies["INR"]?.symbol,
            value: eventList?.activeCurrencies["INR"]?.value,
          }
        : eventList?.userCountry.toLowerCase() === "bangladesh"
        ? {
            symbolLeft: eventList?.activeCurrencies["BDT"]?.symbol,
            value: eventList?.activeCurrencies["BDT"]?.value,
          }
        : {
            symbolLeft: eventList?.activeCurrencies["USD"]?.symbol,
            value: eventList?.activeCurrencies["USD"]?.value,
          }
    );
    if (eventList) {
      setEvent(eventList.evntDetails.map((i) => i.eventName));
    }
  }, [eventList]);

  const headers = [
    {
      label: "ORDER #",
      filter: "invoiceId",
      filterable: false,
      render: (el) => <span>{el}</span>,
    },
    {
      label: "ORDER DATE",
      filter: "createdAt",
      filterable: false,
      render: (el) => <span>{el}</span>,
    },
    {
      label: "TICKET AMOUNT",
      filter: "amount",
      filterable: true,
      render: (el) => (
        <span>{`${
          conversionFactor?.symbolLeft ?? "$"
        } ${CustomCurrencyConverter(el, conversionFactor?.value)}`}</span>
      ),
    },
    {
      label: "EVENT DATE",
      filter: "eventDate",
      filterable: false,
      render: (el) => <span>{el}</span>,
    },
    {
      label: "EVENT NAME",
      filter: "eventName",
      filterable: true,
      render: (el) => <span>{el}</span>,
    },

    {
      label: "DEBIT TC",
      filter: "trainingWallet",
      filterable: true,
      render: (el) => (
        <span>{`${
          conversionFactor?.symbolLeft ?? "$"
        } ${CustomCurrencyConverter(el, conversionFactor?.value)}`}</span>
      ),
    },
    {
      label: "PAYMENT MODE",
      filter: "paymentMethod",
      filterable: true,
      render: (el) => <span>{el || "NA"}</span>,
    },
    {
      label: "PAYMENT AMOUNT",
      filter: "paidAmount",
      filterable: true,
      render: (el) => (
        <span>{`${
          conversionFactor?.symbolLeft ?? "$"
        } ${CustomCurrencyConverter(el, conversionFactor?.value)}`}</span>
      ),
    },
  ];
  const gotopage = (page) => {
    setCurrentPage(page);
  };

  const table =
    eventReport?.data && eventReport?.data?.length ? (
      <>
        <h1  className="bebas-neue-regular gta-b">
          EVENT TICKETS ORDER REPORT
        </h1>

        <div className="report-table-container-flex">
          <div className="report-table-container scrollbar-blue">
            <CommonTableComponent
              headers={headers}
              datas={eventReport?.data}
              firstElementSticky={true}
              searchOption={false}
              isLoading={eventReport?.isLoading}
            />
          </div>
        </div>
      </>
    ) : null;

  const pagingation =
    !eventReport?.isLoading && table ? (
      <div className="report-table-container-flex">
        <div className="pagination-container">
          <TablePagination
            startPage={START_PAGE}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            toNextPage={toNextPage}
            toLastPage={toLastPage}
            toPreviousPage={toPreviousPage}
            toFirstPage={toFirstPage}
            exportButton={false}
          />
        </div>
      </div>
    ) : null;

  return (
    <div className="container-bg-design common-div-container">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginTop: "6vh" }} className="bebas-neue-regular gta-b">
          EVENTS TICKET SHOPPING CART
        </h1>
        <EventList
          setTotalAmount={setTotalAmount}
          totalAmount={totalAmount}
          userCountry={eventList?.userCountry}
          userData={eventList?.userData}
          conversionFactor={conversionFactor}
          balance={eventList?.tainingWalletBalance ?? 0}
          eventList={eventList?.evntDetails ?? []}
          events={events}
        />
        {table}
        {pagingation}
      </div>
    </div>
  );
};

export default EventPurchase;
