import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import CommonTableComponent from "../Common/CommonTableComponent";
import moment from "moment";
import CustomFileInput from "../Common/CustomFileInput";

const KycDetails = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [files, setFiles] = useState(new Map());

    // ------------------------------------- API -------------------------------------
    const details = ApiHook.CallKycDetails();
    const kycMutation = ApiHook.CallKycUploads();

    const handleUploadImage = () => {
        if (!files.size) return;

        let uploadFiles = [];

        for (const [key, value] of files) {
            value.forEach((file) => {
                uploadFiles.push({ File: file, keyType: key });
            });
        }

        kycMutation.mutate(
            {
                files: uploadFiles,
                type: "kyc",
            },
            {
                onSuccess: (res) => {
                    if (res?.status) {
                        toast.success(t("fileUploadedSuccessfully"));
                        queryClient.invalidateQueries({
                            queryKey: ["kyc-details"],
                        });
                        setFiles(new Map());
                    } else {
                        if (res.data.code === 1124) {
                            toast.error(t("kycVerified"));
                        } else if (res.data.code) {
                            toast.error(t(res?.data?.description));
                        } else {
                            toast.error(res?.message);
                        }
                    }
                },
            }
        );
    };

    const fileInput = details?.data?.kycCategory.length ? (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "40px",
            }}
        >
            <h1
                style={{ marginTop: "6vh" }}
                className="bebas-neue-regular gta-b"
            >
                {t("upload_kyc_document")}
            </h1>

            <div className="kyc-upload-from">
                {details?.data?.kycCategory.map((item, key) => (
                    <div key={item.id}>
                        <label className="custom-label">
                            {t(item.category)}
                        </label>
                        <CustomFileInput
                            id={item.id}
                            item={item}
                            files={files}
                            setFiles={setFiles}
                        />
                    </div>
                ))}
                <div className="my-3">
                    <span
                        style={{ textAlign: "center", marginBottom: "5px" }}
                        className="kyc-supported-formats"
                    >
                        *Supported formats: Jpeg, Pdf, Png/Maximum uploaded file
                        size: 2MB
                    </span>
                    <button
                        className="button-maxwidth mt-3"
                        onClick={handleUploadImage}
                    >
                        <span style={{ fontWeight: 700, color: "white" }}>
                            SUBMIT
                        </span>
                    </button>
                </div>
            </div>
        </div>
    ) : null;

    const headers = [
        {
            label: "IBE #",
            filter: "ibe",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "NAME",
            filter: "ibeName",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "CITY",
            filter: "ibeCity",
            filterable: true,
            render: (el) => <span>{el || "NA"}</span>,
        },
        {
            label: "STATE",
            filter: "ibeState",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "DATE",
            filter: "date",
            filterable: false,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "UPLOADED FILES",
            filter: "category",
            filterable: false,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "STATUS",
            filter: "status",
            filterable: false,
            render: (el) => (
                <span
                    className="custom-table-cell-without"
                    style={statusColorLookUp(el)}
                >
                    {el}
                </span>
            ),
        },
        {
            label: "REMARKS",
            filter: "reason",
            filterable: false,
            render: (el) => <span>{el || "NA"}</span>,
        },
    ];
    const statusColorLookUp = (el) => {
        if (el === "pending") {
            return { backgroundColor: "#c7e6d3", color: "#66bc7e" };
        }
        if (el === "Rejected") {
            return { backgroundColor: "#FEE4D1", color: "#9B5731" };
        }
        if (el === "APPROVED") {
            return { backgroundColor: "#E4F0F2", color: "#2E8CA5" };
        }
    };
    const table =
        details?.data?.userKyc && details?.data?.userKyc?.length ? (
            <div className="d-flex justify-content-center">
                <div className="report-table-container-flex">
                    <div className="report-table-container scrollbar-blue">
                        <CommonTableComponent
                            headers={headers}
                            datas={details?.data?.userKyc}
                            firstElementSticky={true}
                            searchOption={false}
                            isLoading={details?.isLoading}
                        />
                    </div>
                </div>
            </div>
        ) : null;

    return (
        <div className="container-bg-design common-div-container">
            <div style={{ position: "relative", zIndex: "1" }}>
                {fileInput}
                {table}

                {/* NO DATA */}
                {!table && !details?.data?.kycCategory?.length && (
                    <div className="no-data-div">
                        <div className="no-data-div-image">
                            <img src="/images/nodata-image.png" alt="" />
                        </div>
                        <p>{t("noDataFound")}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default KycDetails;
