import React, { useState, useRef } from "react";
import Input from "./FormInput.jsx";
import logo_user from "../../assests/images/logo_user.png";
import welcomeImg from "../../assests/images/welcomeImg.svg";
import SubmitButton from "../Common/buttons/SubmitButton.jsx";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ApiHook } from "../../hooks/apiHook.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CommonInput from "../Common/CommonInputComponent.jsx";

const LoginForm = ({ params, selectedPage, setSelectedPage }) => {
    const companyDetails = useSelector(
        (state) => state.replica?.companyDetails
    );
    const favicon = document.getElementById("dynamic-favicon");
    if (favicon) {
        favicon.href = companyDetails?.favicon;
    }
    const [credentials, setCredentials] = useState({
        username: params.username ?? "",
        password: params.username ? "12345678" : "",
    });

    const [errorMessage, setErrorMessage] = useState({
        username: null,
        password: null,
        userCredentials: null,
    });

    const [errorMessageResetPass, setErrorMessageResetPass] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    // const [selectedPage, setSelectedPage] = useState("login");
    const [username, setUsername] = useState("");
    const isSubmittingRef = useRef(false);

    const loginMutation = ApiHook.CallLoginUser();
    const verifyOtp = ApiHook.Call2faVerifyOtp();
    const forgotPasswordMutation = ApiHook.CallForgotPassword();
    const [otp, setotp] = useState("");
    const { isEnabled, twoFadata } = useSelector(
        (state) => state?.user?.twoFaAuth
    );

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleForgotUsername = (e) => {
        const { value } = e.target;
        setUsername(value);
        
        setErrorMessageResetPass(null)
    };
    const handleChange = (name, value) => {
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [name]: value,
        }));
        setErrorMessage((prev) => ({
            ...prev,
            [name]: null,
        }));

        setErrorMessage((prev) => ({
            ...prev,
            userCredentials: null,
        }));

        if (value === null || value === "") {
            setErrorMessage((prev) => ({
                ...prev,
                [name]: "*Required",
            }));
        }
    };

    const isFormValid = () => {
        return (
            credentials?.password.trim() !== "" &&
            credentials?.username.trim() !== ""
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isSubmittingRef.current) {
            isSubmittingRef.current = true;
            await loginMutation.mutateAsync(credentials, {
                onSuccess: (res) => {
                    if (res?.code === 1003) {
                        setErrorMessage({
                            userCredentials: res?.data,
                        });
                    } else if (res?.code === 1037) {
                        setErrorMessage({
                            userCredentials: res?.data,
                        });
                    } else {
                        setErrorMessage({
                            userCredentials: res?.data,
                        });
                    }
                },
            });
            isSubmittingRef.current = false;
        }
    };

    const resetPassword = async (event) => {
        event.preventDefault();
        if (!isSubmittingRef.current) {
            isSubmittingRef.current = true;
            const data = {
                username: username,
            };
            await forgotPasswordMutation.mutateAsync(data, {
                onSuccess: (res) => {
                    if (res?.status) {
                        // toast.success(res?.data);
                        isSubmittingRef.current = false;
                        setErrorMessageResetPass(null)
                    } else {
                        setErrorMessageResetPass(res?.description)
                        // toast.error(res?.description);
                        isSubmittingRef.current = false;
                    }
                },
            });
        }
    };

    const handleOtpChange = (e) => {
        const { value } = e.target;
        setotp(value);
    };

    const handleVerifyotp = async (e) => {
        e.preventDefault();
        try {
            await verifyOtp.mutateAsync(
                {
                    admin_user_name: credentials.admin_user_name,
                    username: credentials.username,
                    password: credentials.password,
                    secretKey: twoFadata?.secretKey,
                    otp: otp,
                },
                {
                    onSuccess: (res) => {
                        if (res?.code === 1132) {
                            setErrorMessage({
                                userCredentials: res?.data,
                            });
                        } else if (res?.code === 1037) {
                            setErrorMessage({
                                userCredentials: res?.data,
                            });
                        } else {
                            setErrorMessage({
                                userCredentials: res?.data,
                            });
                        }
                    },
                }
            );
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <p className="loginSectionHeading">
                {selectedPage === "login"
                    ? "BRAND EXECUTIVE LOGIN"
                    : "PASSWORD RESET"}
            </p>
            <div className="col-md-12 logincredDetail">
                <div className="loginFormSec login_left_section">
                    {/* <div className="loginLogo">
                        <img src={companyDetails?.logo} alt="" />
                    </div> */}
                    {/* <p>{`Welcome Back to ${companyDetails?.name}`}</p> */}
                    {selectedPage === "login" ? (
                        <>
                            {
                                <form onSubmit={handleSubmit}>
                                    {/* {errorMessage?.userCredentials && (
                                        <div
                                            style={{
                                                color: "red",
                                                textAlign: "center",
                                            }}
                                        >
                                            {errorMessage?.userCredentials}
                                        </div>
                                    )}
                                    <Input
                                        type="text"
                                        id="fname"
                                        name="username"
                                        placeholder="Username"
                                        value={credentials.username}
                                        onChange={handleChange}
                                    />
                                    {errorMessage?.username && (
                                        <div style={{ color: "red" }}>
                                            {errorMessage?.username}
                                        </div>
                                    )}
                                    <div className="LoginPasswordField">
                                        <Input
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            id="password"
                                            name="password"
                                            placeholder="Password"
                                            value={credentials.password}
                                            onChange={handleChange}
                                        />
                                        <InputAdornment
                                            position="end"
                                            style={{
                                                position: "absolute",
                                                right: 0,
                                                top: 32,
                                            }}
                                        >
                                            <IconButton
                                                onClick={handleShowPassword}
                                                onMouseDown={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                {showPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    </div>
                                    {errorMessage?.password && (
                                        <div style={{ color: "red" }}>
                                            {errorMessage?.password}
                                        </div>
                                    )} */}

                                    <CommonInput
                                        label={"Username"}
                                        placeholder={"Username"}
                                        backgroundColor={"#e4f0f2"}
                                        value={credentials.username}
                                        onChange={(e) => {
                                            handleChange(
                                                "username",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errorMessage?.username !== "" && (
                                        <span className="error-message-validator">
                                            {errorMessage?.username}
                                        </span>
                                    )}
                                    <CommonInput
                                        label={"password"}
                                        placeholder={"********"}
                                        backgroundColor={"#e4f0f2"}
                                        type={
                                            !showPassword ? "password" : "text"
                                        }
                                        onChange={(e) => {
                                            handleChange(
                                                "password",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errorMessage?.userCredentials !== "" && (
                                        <span className="error-message-validator">
                                            {errorMessage?.userCredentials}
                                        </span>
                                    )}
                                    {errorMessage?.password !== "" && (
                                        <span className="error-message-validator">
                                            {errorMessage?.password}
                                        </span>
                                    )}

                                    <div className="loginSection-showPassword">
                                        <div
                                            className="loginSection-checkbox"
                                            onClick={handleShowPassword}
                                        >
                                            {showPassword && (
                                                <div className="loginSection-checkbox-checked"></div>
                                            )}
                                        </div>
                                        <article
                                            onClick={handleShowPassword}
                                            className='forgot-pass-link'
                                        >
                                            {" "}
                                            SHOW PASSWORD
                                        </article>
                                    </div>
                                    <div className="loginBtn">
                                        <SubmitButton
                                            // isSubmitting={!isFormValid()}
                                            click={handleSubmit}
                                            text={
                                                loginMutation.isLoading
                                                    ? "Submitting..."
                                                    : "Login"
                                            }
                                            className={"btn"}
                                        />
                                    </div>
                                    <article
                                        // className="forgetPassword"
                                        onClick={() =>
                                            setSelectedPage("resetPassword")
                                        }
                                     className='forgot-pass-link'
                                    >
                                        FORGOT PASSWORD?
                                    </article>
                                </form>
                            }
                        </>
                    ) : (
                        <form onSubmit={resetPassword}>
                            {/* <Input
                  <div className="loginSection-showPassword my-4">
                    <div
                      className="loginSection-checkbox"
                      onClick={handleShowPassword}
                    >
                      {showPassword && (
                        <div className="loginSection-checkbox-checked"></div>
                      )}
                    </div>
                    <article
                    className={"forgot_showpass"}
                      onClick={handleShowPassword}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      SHOW PASSWORD
                    </article>
                  </div>
                  <div className="loginBtn">
                    <SubmitButton
                      isSubmitting={!isFormValid()}
                      click={handleSubmit}
                      text={loginMutation.isLoading ? "Submitting..." : "Login"}
                      className={"btn"}
                    />
                  </div>
                  <article
                    className={"forgot_showpass mt-4"}
                    // className="forgetPassword"
                    onClick={() => setSelectedPage("resetPassword")}
                    style={{
                      color: "#8b4e2b",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    FORGET PASSWORD?
                  </article>
                </form>
              }
            </>
          ) : (
            <form onSubmit={resetPassword}>
              {/* <Input
                                type="text"
                                id="fname"
                                name="username"
                                placeholder="Username"
                                value={username}
                                onChange={handleForgotUsername}
                            />
                            {errorMessage?.username && (
                                <div style={{ color: "red" }}>
                                    {errorMessage?.username}
                                </div>
                            )} */}

                            <CommonInput
                                label={"USERNAME"}
                                placeholder={"USERNAME"}
                                backgroundColor={"#e4f0f2"}
                                value={username}
                                onChange={handleForgotUsername}
                            />
                            {errorMessageResetPass !== "" && (
                                        <span className="error-message-validator">
                                            {errorMessageResetPass}
                                        </span>
                                    )}
                            <div className="loginBtn">
                                <SubmitButton
                                    isLoading={
                                        forgotPasswordMutation.status ===
                                        "loading"
                                    }
                                    click={resetPassword}
                                    text={"SUBMIT"}
                                    className={"btn"}
                                />
                            </div>
                            <article
                                // className="forgetPassword"
                                onClick={() => {setSelectedPage("login") ; setErrorMessageResetPass(null)}}
                                style={{
                                    color: "#8b4e2b",
                                    textAlign: "center",
                                    cursor: "pointer",
                                }}
                            >
                                SIGN IN?
                            </article>
                        </form>
                    )}
                </div>
            </div>
            {/* <div className="col-md-6">
                <div className="welcomeImgSec">
                    <div className="welcomHeadSec">
                        <p>Hello,</p>
                        <h2>Welcome</h2>
                        <p>Enter your credentials and login</p>
                    </div>
                    <div className="welcomeImg">
                        <img src={welcomeImg} alt="" />
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default LoginForm;
